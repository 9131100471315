import clsx from "clsx";
import moment from "moment";
import { history } from "src/stores";
import { formatPrice } from "src/utils";
import { IMysteryBoxPrizeDetail } from "src/types/mysteryBox";
import { BsArrowRepeat, BsBoxArrowUp } from "react-icons/bs";
import { CaseOpeningModal } from "src/components/case-opening-modal";
import { CaseOpeningSelectList } from "src/components/case-opening-select-list";
import { getCurrencyIcon } from "src/utils/image";
import { calculateWeaponPrice } from "src/utils/case";

interface ICaseOpeningBoxMultiResultModal {
  className?: string;
  prizes: IMysteryBoxPrizeDetail[];
  isVisible: boolean;
  isValuable: boolean[];
  isSelling: boolean;
  bonus: number;
  onClose: () => void;
  onRetry: () => void;
  onSell: (type: "sell-selected" | "sell-all", index?: number) => void;
}

const calculatePrice = (prizes: IMysteryBoxPrizeDetail[]) =>
  formatPrice(
    prizes.reduce((acc, item) => acc + calculateWeaponPrice(item), 0)
  );

const CaseOpeningBoxMultiResultModal = ({
  className,
  prizes,
  isVisible,
  isValuable,
  bonus,
  onClose,
  onRetry,
  onSell,
}: ICaseOpeningBoxMultiResultModal) => {
  const calculatedAllPrice = calculatePrice(prizes);
  const currency = prizes[0].sellCurrency || "USDT";

  const handleGoToInventory = () => {
    if (currency === "GDD") {
      return history.push("/dashboard#gdd-case");
    }

    if (currency === "MAX") {
      return history.push("/dashboard#max-case");
    }

    return history.push("/dashboard#case");
  };

  return (
    <CaseOpeningModal
      className={className}
      isVisible={isVisible}
      title={<span className="mt-space-2 block">Your Drop</span>}
      bonus={bonus}
      content={
        <div className="relative w-full h-[280px] md:h-[320px] flex flex-col justify-center items-center">
          <div className="absolute top-1 blender-medium px-space-6 w-full">
            <div className="flex justify-between md:hidden items-center text-grey-800 leading-none">
              <h5 className="text-s text-white leading-none mb-0">Inventory</h5>
              <div className="text-[8px]">
                Last updated {moment(new Date()).format("DD/MM/YY HH:mm:ss")}
              </div>
            </div>
            <button
              className={clsx(
                "block md:hidden mt-1",
                "w-full h-[24px] flex items-center justify-center",
                "backdrop-blur bg-black-400 border rounded-xs border-grey-800 text-white text-s cursor-pointer normal-case font-normal"
              )}
              onClick={handleGoToInventory}
            >
              My inventory
              <img
                className="w-[10px] h-[10px]"
                src="/assets/v4/dashboard.png"
                alt=""
              />
            </button>
          </div>
          <div className="absolute top-10 md:top-0 left-0 w-full h-full py-space-2 px-space-4 md:py-space-4 md:px-space-8">
            <CaseOpeningSelectList
              className="max-h-full overflow-y-auto scrollbar-hide overscroll-contain"
              data={prizes}
              isValuable={isValuable}
              onSell={onSell}
            />
          </div>
        </div>
      }
      footerClassName="pt-space-2 mx-space-0 md:mx-[25px] px-space-4"
      footer={
        <div className="translate-y-[30px] md:translate-y-0 flex items-start w-full max-h-[138px] gap-space-2 md:gap-space-4">
          {/* Left Inventory area */}
          <div className="hidden md:block flex-0">
            <div className="grid grid-cols-3 grid-rows-[16px_28px_28px] md:gap-xs">
              <h5 className="text-grey-100 text-l col-span-1 leading-none">
                Inventory
              </h5>
              <div className="text-grey-800 text-m col-span-2 text-right">
                Last updated {moment(new Date()).format("DD/MM/YY HH:mm:ss")}
              </div>
              <button
                className={clsx(
                  "col-span-full",
                  "flex items-center justify-center gap-space-1",
                  "backdrop-blur bg-black-400 border rounded-xs border-grey-800 text-white text-s cursor-pointer normal-case"
                )}
                onClick={handleGoToInventory}
              >
                <div className="leading-none">My inventory</div>
                <img
                  className="w-[16px] h-[16px] md:w-[10px] md:h-[10px]"
                  src="/assets/v4/dashboard.png"
                  alt=""
                />
              </button>
            </div>
          </div>
          {/* Dividing */}
          <div className="hidden md:block basis-[1px] h-[100px] shrink-0 case-opening-dividing-line" />
          {/* Right buttons area */}
          <div className="flex-1">
            <div className="grid grid-cols-2 grid-rows-[16px_28px_28px] gap-space-2 md:gap-xs">
              <h5 className="text-s md:text-l text-grey-100 leading-none">
                Sell all:
              </h5>
              <div className="flex items-center justify-end">
                <div className="text-native-green text-s md:text-l leading-none">
                  +
                </div>
                <img
                  src={getCurrencyIcon(currency || "USDT")}
                  className="w-[12px] h-[12px] md:w-[14px] md:h-[14px] mr-space-1"
                  alt=""
                />
                <div className="text-native-green text-s md:text-l leading-none">
                  {calculatedAllPrice}
                </div>
              </div>
              <button
                className={clsx(
                  "case-opening-sell-btn-green",
                  "col-span-full",
                  "w-full h-full flex items-center justify-center gap-space-2",
                  "border rounded-xs border-native-green text-white text-l normal-case cursor-pointer font-normal"
                )}
                onClick={() => onSell("sell-all")}
              >
                <BsBoxArrowUp className="text-[12px]" />
                <span>Sell all</span>
              </button>
              <button
                className={clsx(
                  "group/retry w-full h-full flex items-center justify-center gap-space-1",
                  "col-span-full",
                  "text-orange-800 text-l cursor-pointer border-none normal-case  font-normal"
                )}
                onClick={onRetry}
              >
                <BsArrowRepeat className="text-orange-800 transition-transform duration-300 group-hover/retry:rotate-180" />
                <div>Try Again</div>
              </button>
            </div>
          </div>
        </div>
      }
      onClose={onClose}
    />
  );
};

export { CaseOpeningBoxMultiResultModal };
