export const REFERRAL_LEVELS = [
  {
    level: 1,
    title: "Level 1",
    description: "Invite 1 friend",
    bonus: "3%",
    mainColor: "#749BC1"
  },
  {
    level: 2,
    title: "Level 2",
    description: "Invite 5 friends",
    bonus: "5%",
    mainColor: "#C36D48"
  },
  {
    level: 3,
    title: "Level 3",
    description: "Invite 10 friends",
    bonus: "10%",
    mainColor: "#C5C5C5"
  },
  {
    level: 4,
    title: "Level 4",
    description: "Invite 20 friends",
    bonus: "30%",
    mainColor: "#FFC71E"
  },
  {
    level: 5,
    title: "Level 5",
    description: "Invite 50 friends",
    bonus: "40%",
    mainColor: "#FF134B"
  }
]

export const getMainColor = (level: number) => {
  return REFERRAL_LEVELS.find((referralLevel) => referralLevel.level === level)?.mainColor
}

export const getBonus = (level: number) => {
  return REFERRAL_LEVELS.find((referralLevel) => referralLevel.level === level)?.bonus
}

export const getIconName = (level: number) => {
  return `referral-level-${level}`
}