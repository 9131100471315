import clsx from "clsx";
import { openSuccessNotification } from "src/components";
import { DISPLAY_MODE_REFERRAL } from "src/config/dashboard";
import { ReactComponent as LinkIcon } from "src/svg-icons/white-link.svg";
import { ReactComponent as CopyIcon } from "src/svg-icons/white-copy.svg";

interface iDashboardReferral {
  setDashboardDisplayMode: (val: string) => void;
  userReferralCode: string | null;
}

export const DashboardReferral = (props: iDashboardReferral) => {
  const { setDashboardDisplayMode, userReferralCode } = props;
  const btnStyle = `border-[1px] border-solid p-space-0 md:p-space-2 rounded-xs cursor-pointer flex items-center justify-center flex-row gap-space-1 backdrop-blur-[4px] blender-medium text-s md:text-l`;
  const v2PrimaryHoverStyle = `hover:drop-shadow-[0_4px_4px_rgba(254, 82, 25,0.5)] transition drop-shadow duration-700`;

  const handleCopyReferralLink = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}?referral=${userReferralCode}`
    );
    openSuccessNotification("You have copied your invitation link");
  };

  const copyReferralCode = () => {
    if (userReferralCode) {
      navigator.clipboard.writeText(userReferralCode);
      openSuccessNotification("You have copied your referral code");
    }
    return;
  };

  return (
    <div
      className="rounded-s relative p-space-2 sm:p-space-4 text-center md:text-left"
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
          url('/assets/referral/referral-banner-hero.png') 110% 15% / 50% no-repeat,
          url('/assets/referral/referral-banner-background.png') 0 70% / cover no-repeat`,
      }}
    >
      <div className="flex items-center justify-center md:justify-start mb-space-2 md:mb-space-6">
        <p className="text-l sm:text-[24px] leading-[19px] sm:leading-[28px] text-grey-100 font-[500] blender-medium">
          Invite a friend and get
        </p>
        <img
          src="/assets/v4/geda-star.png"
          alt="geda-star"
          className="w-[20px] h-[20px]"
        />
        <p className="text-l text-orange-800">100</p>
      </div>
      <div className="flex items-center justify-center md:justify-start flex-wrap w-full md:w-[330px]">
        <p className="text-l leading-[20px] text-grey-100 font-[500] blender-medium tracking-[1px]">
          Give a friend promo code and receive
        </p>
        <img
          src="/assets/v4/geda-star.png"
          alt="geda-star"
          className="w-[20px] h-[20px]"
        />
        <p className="text-l text-orange-800">100</p>
        <p className="text-l leading-[20px] text-grey-100 font-[500] blender-medium tracking-[1px]">
          for every new friend you invite you will both receive bonus
        </p>
      </div>
      <div className="md:flex items-center justify-between gap-space-4 mt-2">
        <div className="btn-group flex gap-2">
          <button
            className={clsx(
              `${btnStyle} ${v2PrimaryHoverStyle} sm:w-auto bg-[#242424] opacity-80 border-grey-800`,
              "flex-1 md:flex-none"
            )}
            onClick={handleCopyReferralLink}
          >
            Invitation link
            <LinkIcon className="size-[16px]" />
          </button>
          <button
            className={clsx(
              `${btnStyle} ${v2PrimaryHoverStyle} sm:w-auto bg-[#242424] opacity-80 border-grey-800`,
              "flex-1 md:flex-none"
            )}
            onClick={copyReferralCode}
          >
            {userReferralCode}
            <CopyIcon className="size-[16px]" />
          </button>
        </div>
        <button
          className={clsx(
            `
            ${btnStyle} ${v2PrimaryHoverStyle} bg-[#FE521933] border-orange-600"`,
            "w-full sm:w-[160px]  mt-2 md:mt-0"
          )}
          onClick={() => setDashboardDisplayMode(DISPLAY_MODE_REFERRAL)}
        >
          More Information
        </button>
      </div>
    </div>
  );
};
