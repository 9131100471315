import { useMemo, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import { openSuccessNotification, openErrorNotification } from "src/components";
import { IDashboardProfile } from "src/types/dashboard";
import { formatLastUpdateDateTIme } from "src/utils";
import { useIsPcScreenWidth } from "src/utils/rwd";
import { BsFillBackpackFill } from "react-icons/bs";
import { CURRENCIES } from "src/config/currencies";
import {
  FiChevronDown,
  FiChevronUp,
  FiInfo,
  FiCopy,
  FiShare2,
  FiDownload,
  FiUpload,
} from "react-icons/fi";
import { ReactComponent as SettingIcon } from "src/svg-icons/white-setting.svg";
import { ReactComponent as ChevronRightIcon } from "src/svg-icons/white-chevron-right.svg";
import { ReactComponent as ChevronLeftIcon } from "src/svg-icons/gray-chevron-left.svg";
import { SlWallet } from "react-icons/sl";
import { RiArrowGoBackLine } from "react-icons/ri";
import {
  DISPLAY_MODE_DEFAULT,
  DISPLAY_MODE_CASE,
  DISPLAY_MODE_SPIN,
  DISPLAY_MODE_REFERRAL,
} from "src/config/dashboard";
import { useAuth } from "../auth-context";
import clsx from "clsx";
import QRCode from "react-qr-code";

interface iPersonalReferralInfo {
  dataReceivedDate: Date | null;
  dashboardDisplayMode: string;
  isExpand: boolean;
}

const btnStyle = `w-full border-[1px] border-solid p-space-2 rounded-xs cursor-pointer mx-auto flex items-center justify-center flex-row gap-space-1 backdrop-blur-[4px]`;
const greyHoverStyle = `hover:drop-shadow-[0_4px_4px_rgba(115,115,115,0.5)] transition drop-shadow duration-700`;
const greenHoverStyle = `hover:drop-shadow-[0_4px_4px_rgba(30,187,113,0.5)] transition drop-shadow duration-700`;

const PersonalReferralInfo = (props: iPersonalReferralInfo) => {
  const { dataReceivedDate, dashboardDisplayMode, isExpand } = props;

  return (
    <>
      <div
        className={`grid ${
          dashboardDisplayMode === DISPLAY_MODE_REFERRAL
            ? "grid-rows-[1fr] mt-space-4"
            : "grid-rows-[0fr]"
        } transition-all duration-500`}
      >
        <div className="overflow-hidden">
          <div
            className={`grid ${
              isExpand ? "grid-rows-[1fr] mb-space-4" : "grid-rows-[0fr]"
            } transition-all duration-500`}
          >
            <div className="grid gap-space-4 overflow-hidden">
              <div>
                <p className="text-l sm:text-[24px] leading-[19px] sm:leading-[28px] text-grey-100 font-[700] blender-medium">
                  Referral
                </p>
                {dataReceivedDate && (
                  <p className="text-s leading-[14px] text-grey-800 blender-medium mt-space-1">
                    Last updated {formatLastUpdateDateTIme(dataReceivedDate)}
                  </p>
                )}
              </div>
              <div className="grid grid-cols-3 gap-space-1">
                <div className="flex flex-col gap-space-1 sm:gap-space-2">
                  <div className="flex flex-col items-center gap-space-1 min-h-[40px]">
                    <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-600">
                      Total Invites
                    </p>
                  </div>
                  <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-100 break-words">
                    0
                  </p>
                </div>
                <div className="flex flex-col gap-space-1 sm:gap-space-2">
                  <div className="flex flex-col items-center gap-space-1 min-h-[40px]">
                    <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-600">
                      Rebate
                      <br />
                      Rewards
                    </p>
                  </div>
                  <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-100 break-words">
                    10%
                  </p>
                </div>
                <div className="flex flex-col gap-space-1 sm:gap-space-2">
                  <div className="flex flex-col items-center gap-space-1 min-h-[40px]">
                    <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-600">
                      New Account
                      <br />
                      Bonus
                    </p>
                  </div>
                  <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-100 break-words">
                    0%
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
interface iCopyInviteInfo {
  userReferralCode: string | null;
}
const CopyInviteInfo = (props: iCopyInviteInfo) => {
  const { userReferralCode } = props;
  const [referralPassed] = useState<boolean>(false);
  const [referralCode, setReferralCode] = useState<string>("");

  const handleShare = async () => {
    // set HTTPS=true in .env file can make navigator.share work in mobile
    const blob = await fetch("assets/referral/referral-inviteQR.png").then(
      (r) => r.blob()
    );
    const data = {
      files: [new File([blob], "referral-inviteQR.png", { type: "image/png" })],
      title: "Share Invitation Link & Referral Code",
      text: "Come to join Geda and get 100 GS for free! Enter the code 0B22J06 to get started!",
      url: "https://www.geda.io",
    };
    try {
      if (!navigator?.canShare(data)) {
        console.error("Can't share");
        return;
      }
      await navigator.share(data);
    } catch (err) {
      openErrorNotification(String(err));
    }
  };

  return (
    <>
      {/* replace hidden to flex for show */}
      <div className="hidden justify-between">
        <p>Have a code?</p>
        <div className="flex items-center">
          <p className="text-[#acacac]">How to earn</p>
          <FiInfo className="text-[#acacac] size-3 ml-1" />
        </div>
      </div>
      {/* remove hidden to show */}
      <input
        className={clsx(
          "mt-2 w-full border-[1px] border-solid bg-black-800 py-space-2 px-space-2 placeholder:text-black-100 rounded-xs text-white focus:outline-none",
          referralPassed ? "border-orange-800" : "border-black-100",
          "hidden"
        )}
        type="string"
        value={referralCode}
        placeholder="Enter Referral Code Here '1234567'"
        onInput={(e) => {
          setReferralCode((e.target as HTMLInputElement).value);
        }}
      />
      {/* remove hidden to show */}
      <button
        className={clsx(
          `${btnStyle} mt-2`,
          referralPassed
            ? "border-orange-800 bg-orange-800/[.3]"
            : "border-[#a3a3a3] bg-[#5b5b5b] opacity-50",
          "hidden"
        )}
        disabled={!referralPassed}
      >
        <p className="normal-case">Apply</p>
      </button>
      <div className="mt-3">
        <p>Share to earn!</p>
      </div>
      <div className="flex justify-center items-center gap-2 mt-2">
        <input
          className="basis-2/3 w-full border-[1px] border-solid border-black-100 bg-black-800 py-space-2 px-space-2 placeholder:text-black-100 rounded-xs text-white"
          type="string"
          value={userReferralCode || ""}
          readOnly
        />
        <button
          className={`${btnStyle} basis-1/3 border-[#737373]`}
          onClick={() => {
            navigator.clipboard.writeText(userReferralCode || "");
            openSuccessNotification("You have copied your Referral Code.");
          }}
        >
          <p className="normal-case">Copy</p>
          <FiCopy />
        </button>
      </div>
      <div className="flex justify-center items-center gap-2 mt-2">
        <input
          className="basis-2/3 w-full border-[1px] border-solid border-black-100 bg-black-800 py-space-2 px-space-2 placeholder:text-black-100 rounded-xs text-white truncate"
          type="string"
          value={`${process.env.REACT_APP_BASE_URL}?referral=${userReferralCode}`}
          readOnly
        />
        <button
          className={`${btnStyle} basis-1/3 border-[#737373]`}
          onClick={handleShare}
        >
          <p className="normal-case">Share</p>
          <FiShare2 />
        </button>
      </div>
      <div className="mt-3">
        <QRCode
          size={180}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          fgColor="#ffffff"
          bgColor="transparent"
          value={`${process.env.REACT_APP_BASE_URL}?referral=${userReferralCode}`}
        />
      </div>
    </>
  );
};
interface iDashboardProfile {
  data: IDashboardProfile;
  dataReceivedDate: Date | null;
  dashboardDisplayMode: string;
  userReferralCode: string | null;
  setDashboardDisplayMode: (val: string) => void;
  setDepositModal: (val: boolean) => void;
  setTransferModal: (val: boolean) => void;
  setTransferCurrencyModal: (val: boolean) => void;
}

export const DashboardProfile = (props: iDashboardProfile) => {
  const {
    data,
    dataReceivedDate,
    dashboardDisplayMode,
    setDashboardDisplayMode,
    setDepositModal,
    setTransferModal,
    setTransferCurrencyModal,
  } = props;

  const size: any = useWindowSize();
  const { userInfo, setOpenConnectAddressDialog } = useAuth();

  const [isExpand, setIsExpand] = useState(true);
  const [isShowReferralDetails, setIsShowReferralDetails] = useState(false);
  const [isShowMoreDetails, setIsShowMoreDetails] = useState(false);

  const isPC = useIsPcScreenWidth();

  const shortenedAddress = useMemo(() => {
    if (!userInfo?.address) return "";
    return userInfo?.address.slice(0, 6) + "..." + userInfo?.address.slice(-4);
  }, [userInfo]);

  const copyGUID = () => {
    if (!userInfo) return;
    navigator.clipboard.writeText(userInfo?.guid);
    openSuccessNotification("You have copied your GUID.");
  };

  return (
    <>
      <div className="relative bg-[#19191966] p-space-2 sm:p-space-4 rounded-s backdrop-blur-[8px]">
        {/* Avatar, desktop */}
        <div className="hidden sm:block">
          <div className="grid gap-space-6 justify-center items-center">
            <img
              src={`/assets/v4/avatar.png`}
              alt={`avatar`}
              className="w-[120px] h-[120px] mx-auto"
            />
            <div className="grid gap-space-2 text-center">
              <h1 className="text-[24px] leading-[22px] text-grey-100 acpex mb-0">
                {data?.username}
              </h1>

              <div className="flex flex-row items-center justify-center gap-space-1">
                <p className="text-m leading-[16px] font-[500] blender-medium text-orange-800 cursor-pointer">
                  <span className="text-grey-800">GUID: </span>
                  {userInfo?.guid}
                </p>

                <FiCopy
                  className="w-[16px] h-[16px] text-grey-800 cursor-pointer"
                  onClick={() => copyGUID()}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Avatar, mobile */}
        <div
          className={`${
            dashboardDisplayMode === DISPLAY_MODE_DEFAULT ? "block" : "hidden"
          } sm:hidden`}
        >
          <div className="flex flex-row items-center gap-space-2">
            <img
              src={`/assets/v4/avatar.png`}
              alt={`avatar`}
              className="w-[48px] h-[48px]"
            />
            <div className="flex-[1]">
              <h1 className="text-l leading-[16px] text-grey-100 acpex mb-space-1">
                {data?.username}
              </h1>

              <div className="flex flex-row gap-space-1">
                <p className="text-m leading-[16px] font-[500] blender-medium text-orange-800 cursor-pointer">
                  <span className="text-grey-800">GUID: </span>
                  {userInfo?.guid}
                </p>

                <FiCopy
                  className="w-[16px] h-[16px] text-grey-800 cursor-pointer"
                  onClick={() => copyGUID()}
                />
              </div>
            </div>
            <div
              className="flex flex-row items-center gap-space-1 cursor-pointer"
              onClick={() => setIsExpand(!isExpand)}
            >
              <p
                className={`text-s leading-[14px] font-[500] blender-medium ${
                  isExpand ? "text-grey-800" : "text-grey-100"
                }`}
              >
                {isExpand ? "Hide all" : "Show all"}
              </p>
              {isExpand && (
                <FiChevronUp className="w-[14px] h-[14px] text-grey-800" />
              )}
              {!isExpand && (
                <FiChevronDown className="w-[14px] h-[14px] text-grey-100" />
              )}
            </div>
          </div>
        </div>

        <div>
          {/* Back to Dashboard Button */}
          <div
            className={`${
              dashboardDisplayMode === DISPLAY_MODE_DEFAULT ? "hidden" : "block"
            } ${btnStyle} ${greyHoverStyle} bg-[#242424CC] border-grey-800 mt-0 sm:mt-space-4`}
            onClick={() => setDashboardDisplayMode(DISPLAY_MODE_DEFAULT)}
          >
            <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-100">
              Back to Dashboard
            </p>
            <RiArrowGoBackLine className="w-[10px] h-[10px] text-grey-100" />
          </div>
          {/* Relation to Balance */}
          <div
            className={`grid ${
              dashboardDisplayMode === DISPLAY_MODE_DEFAULT
                ? "grid-rows-[1fr] mt-space-4 sm:mt-space-6"
                : "grid-rows-[0fr]"
            } transition-all duration-500`}
          >
            <div className="overflow-hidden">
              <div
                className={`grid ${
                  isExpand ? "grid-rows-[1fr] mb-space-4" : "grid-rows-[0fr]"
                } transition-all duration-500`}
              >
                {/* Balance Details */}
                <div className="grid gap-space-4 overflow-hidden">
                  <div>
                    <p className="text-l sm:text-[24px] leading-[19px] sm:leading-[28px] text-grey-100 font-[700] blender-medium">
                      Balance
                    </p>
                    {dataReceivedDate && (
                      <p className="text-s leading-[14px] text-grey-800 blender-medium mt-space-1">
                        Last updated{" "}
                        {formatLastUpdateDateTIme(dataReceivedDate)}
                      </p>
                    )}
                  </div>
                  <div className="grid grid-cols-4 gap-space-1">
                    {CURRENCIES.map((currency, index) => (
                      <div
                        key={index}
                        className="flex flex-col gap-space-1 sm:gap-space-2"
                      >
                        <div className="flex flex-col items-center gap-space-1">
                          <img
                            src={currency.icon}
                            alt={currency.initial}
                            className="w-[20px] h-[20px]"
                          />
                          <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-600">
                            {currency.name}
                          </p>
                        </div>
                        <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-100 break-words">
                          {data?.[currency.value as keyof typeof data] || 0}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Deposit Button */}
              <div
                className={`${btnStyle} ${greenHoverStyle} bg-[#1EBB714D] border-native-green`}
                onClick={() => setDepositModal(true)}
              >
                <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-native-green">
                  Top up
                </p>
                <SlWallet className="w-[10px] h-[10px] text-native-green" />
              </div>
            </div>
          </div>
          <div
            className={`grid ${
              dashboardDisplayMode === DISPLAY_MODE_DEFAULT && isExpand
                ? "grid-rows-[1fr] mt-space-4"
                : "grid-rows-[0fr]"
            } transition-all duration-500`}
          >
            <div className="grid grid-cols-2 gap-space-4 overflow-hidden">
              {/* convert to GS button */}
              <div
                className={`${btnStyle} ${greyHoverStyle} bg-[#242424CC] border-grey-800`}
                onClick={() => {
                  setTransferCurrencyModal(true);
                }}
              >
                <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-100">
                  Convert to GS
                </p>
                <FiDownload className="w-[10px] h-[10px] text-grey-100" />
              </div>

              {/* Trasnfer button */}
              <div
                className={`${btnStyle} ${greyHoverStyle} bg-[#242424CC] border-grey-800`}
                onClick={() => setTransferModal(true)}
              >
                <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-100">
                  Transfer
                </p>
                <FiUpload className="w-[10px] h-[10px] text-grey-100" />
              </div>
            </div>
          </div>
          <div
            className={`grid ${
              dashboardDisplayMode === DISPLAY_MODE_DEFAULT
                ? "grid-rows-[1fr] mt-space-4 sm:mt-space-6"
                : "grid-rows-[0fr]"
            } transition-all duration-500`}
          >
            <div className="overflow-hidden">
              <div
                className={`grid ${
                  size?.width >= 760 || isExpand
                    ? "grid-rows-[1fr] mb-space-4"
                    : "grid-rows-[0fr]"
                } transition-all duration-500`}
              >
                {/* Inventory Details */}
                <div className="grid gap-space-4 overflow-hidden">
                  <div>
                    <p className="text-l sm:text-[24px] leading-[19px] sm:leading-[28px] text-grey-100 font-[700] blender-medium">
                      Inventory
                    </p>
                    {dataReceivedDate && (
                      <p className="text-s leading-[14px] text-grey-800 blender-medium mt-space-1">
                        Last updated{" "}
                        {formatLastUpdateDateTIme(dataReceivedDate)}
                      </p>
                    )}
                  </div>
                  <div className="grid grid-cols-3 gap-space-1">
                    <div className="flex flex-col gap-space-2">
                      <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-800">
                        New items
                        <span className="inline-block w-[8px] h-[8px] bg-orange-800 ml-space-1 rounded-full"></span>
                      </p>
                      <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-100">
                        Coming soon!{/* TODO: Mock Data */}
                      </p>
                    </div>
                    <div className="flex flex-col gap-space-2">
                      <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-800">
                        Spin & Earn
                      </p>
                      <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-100">
                        Coming soon!{/* TODO: Mock Data */}
                      </p>
                    </div>
                    <div className="flex flex-col gap-space-2">
                      <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-800">
                        Open Box
                      </p>
                      <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-center text-grey-100">
                        Coming soon!{/* TODO: Mock Data */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* View inventory button */}
              <div
                className={`${btnStyle} ${greyHoverStyle} bg-[#242424CC] border-grey-800`}
                onClick={() => setDashboardDisplayMode(DISPLAY_MODE_SPIN)}
              >
                <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-100">
                  View inventory
                </p>
                <BsFillBackpackFill className="w-[10px] h-[10px] text-grey-100" />
              </div>
            </div>
          </div>
          <div
            className={`grid ${
              dashboardDisplayMode === DISPLAY_MODE_DEFAULT
                ? "grid-rows-[1fr] mt-space-4 sm:mt-space-6"
                : "grid-rows-[0fr] mt-0 sm:mt-0"
            } transition-all duration-500`}
          >
            <div className="overflow-hidden">
              {/* More or Hide details button */}
              <div
                className={`block sm:hidden ${btnStyle} bg-[#242424CC] border-grey-800`}
                onClick={() => setIsShowMoreDetails(!isShowMoreDetails)}
              >
                <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-100">
                  {isShowMoreDetails ? "Hide details" : "More details"}
                </p>
                {isShowMoreDetails && (
                  <FiChevronUp className="w-[14px] h-[14px] text-grey-100" />
                )}
                {!isShowMoreDetails && (
                  <FiChevronDown className="w-[14px] h-[14px] text-grey-100" />
                )}
              </div>

              <div
                className={`grid ${
                  size?.width >= 760 || isShowMoreDetails
                    ? "grid-rows-[1fr] mt-space-4 sm:mt-space-2"
                    : "grid-rows-[0fr]"
                } transition-all duration-500`}
              >
                <div className="grid gap-space-4 sm:gap-space-6 overflow-hidden">
                  {/* Geda Details */}
                  <div className="grid gap-space-4">
                    <p className="text-l sm:text-[24px] leading-[19px] sm:leading-[28px] text-grey-100 font-[700] blender-medium">
                      GeDa stats
                    </p>
                    <div className="grid gap-space-2">
                      <div className="flex flex-row items-center justify-between">
                        <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-600 font-[500] blender-medium uppercase">
                          Total score (season 1):
                        </p>
                        <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200 font-[500] blender-medium">
                          {data?.totalGeDa}
                        </p>
                      </div>
                      <div className="flex flex-row items-center justify-between">
                        <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-600 font-[500] blender-medium">
                          Energy
                        </p>
                        <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200 font-[500] blender-medium">
                          {data?.energy}%
                        </p>
                      </div>
                      <div className="flex flex-row items-center justify-between">
                        <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-600 font-[500] blender-medium">
                          Multiplier
                        </p>
                        <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200 font-[500] blender-medium">
                          {data?.multiplier}%
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Account Details */}
                  <div className="grid gap-space-4">
                    <p className="text-l sm:text-[24px] leading-[19px] sm:leading-[28px] text-grey-100 font-[700] blender-medium">
                      Account
                    </p>
                    <div className="grid gap-space-2">
                      <div className="flex flex-row items-center justify-between">
                        <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-600 font-[500] blender-medium">
                          Joined
                        </p>
                        <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200 font-[500] blender-medium">
                          {data?.joined}
                        </p>
                      </div>
                      <div className="flex flex-row items-center justify-between">
                        <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-600 font-[500] blender-medium">
                          Last successful sign-in
                        </p>
                        <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200 font-[500] blender-medium">
                          Coming soon!{/* TODO: API integration required */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`grid ${
              dashboardDisplayMode === DISPLAY_MODE_DEFAULT
                ? "grid-rows-[1fr] mt-space-4 sm:mt-space-6"
                : "grid-rows-[0fr]"
            } transition-all duration-500`}
          >
            <div className="grid gap-space-4 sm:gap-space-6 overflow-hidden">
              {/* Settings button */}
              <div
                className={`${btnStyle} ${greyHoverStyle} bg-[#242424CC] border-grey-800`}
                onClick={() => {
                  if (userInfo?.address) return;
                  setOpenConnectAddressDialog(true);
                }}
              >
                <p className="blender-medium font-[500] text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-100">
                  {userInfo?.address
                    ? `Wallet Connected to ${shortenedAddress}`
                    : "Connect Wallet"}
                </p>
              </div>
            </div>
          </div>
          {/* Relation to Spin and case */}
          <div
            className={`grid ${
              dashboardDisplayMode === DISPLAY_MODE_SPIN ||
              dashboardDisplayMode === DISPLAY_MODE_CASE
                ? "grid-rows-[1fr] mt-space-4"
                : "grid-rows-[0fr]"
            } transition-all duration-500`}
          >
            <div className="grid gap-space-4 sm:gap-space-6 overflow-hidden">
              {/* Inventory Details */}
              <div className="grid gap-space-4">
                <div>
                  <p className="text-l sm:text-[24px] leading-[19px] sm:leading-[28px] text-grey-100 font-[700] blender-medium">
                    Inventory
                  </p>
                  {dataReceivedDate && (
                    <p className="text-s leading-[14px] text-grey-800 blender-medium mt-space-1">
                      Last updated {formatLastUpdateDateTIme(dataReceivedDate)}
                    </p>
                  )}
                </div>
                <div className="grid gap-space-2">
                  <div className="flex flex-row items-center justify-between">
                    <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-600 font-[500] blender-medium">
                      Total items count
                    </p>
                    <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200 font-[500] blender-medium">
                      Coming soon!{/* TODO: API integration required */}
                    </p>
                  </div>
                  <div className="flex flex-row items-center justify-between">
                    <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-600 font-[500] blender-medium">
                      Total sell value
                    </p>
                    <p className="text-s sm:text-l leading-[14px] sm:leading-[19px] text-grey-200 font-[500] blender-medium">
                      Coming soon!{/* TODO: API integration required */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Relation to Referral */}
          {/* Render in PC */}
          {isPC && dashboardDisplayMode === DISPLAY_MODE_REFERRAL && (
            <PersonalReferralInfo
              dataReceivedDate={dataReceivedDate}
              dashboardDisplayMode={dashboardDisplayMode}
              isExpand={isExpand}
            />
          )}
        </div>
      </div>
      {isPC && dashboardDisplayMode === DISPLAY_MODE_REFERRAL && (
        <div
          className={clsx(
            "relative bg-[#19191966] p-space-2 sm:p-space-4 mt-3 rounded-s backdrop-blur-[8px]"
          )}
        >
          <CopyInviteInfo userReferralCode={userInfo?.referral_code ?? null} />
        </div>
      )}
      {!isPC && dashboardDisplayMode === DISPLAY_MODE_REFERRAL && (
        <>
          <div
            className={clsx(
              "fixed top-15 z-30 transition-all duration-500",
              isShowReferralDetails ? "left-[100%]" : "left-0"
            )}
          >
            <button
              className="relative size-[64px] bg-[#00000030] backdrop-blur-[10px] border-none rounded-[0]"
              onClick={() => setIsShowReferralDetails(true)}
            >
              <SettingIcon className="size-[24px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]" />
              <ChevronRightIcon className="size-[24px] absolute top-[50%] right-0 translate-y-[-50%]" />
            </button>
          </div>
          <div
            className={clsx(
              "fixed z-[35] top-0 size-[100%] bg-[#00000030] backdrop-blur-[10px] transition-all duration-500 ",
              isShowReferralDetails ? "right-0" : "right-[100%]"
            )}
          >
            <div className="relative size-[100%] pt-2 pr-12 pl-4">
              <PersonalReferralInfo
                dataReceivedDate={dataReceivedDate}
                dashboardDisplayMode={dashboardDisplayMode}
                isExpand={isExpand}
              />
              <CopyInviteInfo
                userReferralCode={userInfo?.referral_code ?? null}
              />
              <button
                className="absolute top-0 right-0 w-8 h-full bg-[#00000030] backdrop-blur-[10px] border-none rounded-[0]"
                onClick={() => setIsShowReferralDetails(false)}
              >
                <ChevronLeftIcon className="size-8 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]" />
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};
