import axios from "axios";
import * as Configs from "../config";
import { errorHandling, refreshNewToken, setHeader } from "./api";
import { flow, makeAutoObservable, observable } from "mobx";
import { IConnectWalletToAccount, ILogin } from "src/types/auth";

export class AuthStore {
  user = observable.box(null);

  constructor() {
    makeAutoObservable(this);
  }

  async checkAddress(address: string) {
    const url = `${Configs.API_URL}/v1/auth/check-address?address=${address}`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["success"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getOtp(email: string, type = "login") {
    const url = `${Configs.API_URL}/v1/auth/otp?email=${email}&type=${type}`;
    try {
      const result: any = await axios.get(url);
      if (!result || !result.data?.success) return false;
      return result["data"]["success"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async login(payload: ILogin) {
    const url = `${Configs.API_URL}/v1/auth/login`;
    try {
      const result: any = await axios.post(url, payload);
      if (!result || !result.data?.success) return false;
      const jwt = result.data?.data?.jwt;
      localStorage.setItem("geda-token", jwt);
      return result["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async googleLogin(token: string, referral?: string | null) {
    const url = `${Configs.API_URL}/v1/auth/google-login`;
    try {
      const result: any = await axios.post(url, { token, referral });
      if (!result || !result.data?.success) return false;
      const jwt = result.data?.data?.jwt;
      localStorage.setItem("geda-token", jwt);
      return result["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async connectWalletToAccount(payload: IConnectWalletToAccount) {
    const url = `${Configs.API_URL}/v1/auth/connect-address`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      return result?.data?.success;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async access(payload: any) {
    const url = `${Configs.API_URL}/v1/auth/access`;
    try {
      const result: any = await axios.post(url, payload);
      if (!result || !result.data?.success) return false;
      const jwt = result.data?.data?.jwt;
      localStorage.setItem("geda-token", jwt);
      return result["data"]["success"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async claimPrize(payload: any) {
    const url = `${Configs.API_URL}/v1/auth/my-bag/claim`;
    try {
      const result: any = await axios.put(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.success;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async claimCasePrize(payload: any) {
    const url = `${Configs.API_URL}/v1/auth/my-case-bag/claim`;
    try {
      const result: any = await axios.put(url, payload, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.success;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getMyBag() {
    const url = `${Configs.API_URL}/v1/auth/my-bag`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getMyCaseBag() {
    const url = `${Configs.API_URL}/v1/auth/my-case-bag`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getMyCaseBagByCurrency(currency?: string) {
    const url = `${Configs.API_URL}/v2/auth/my-case-bag${
      currency ? `?currency=${currency}` : ""
    }`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  getProfile = flow(function* (this: AuthStore) {
    const url = `${Configs.API_URL}/v1/auth/profile`;
    try {
      const result: any = yield axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      this.user.set(result?.data?.data);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  });

  async getProfileV2() {
    const url = `${Configs.API_URL}/v1/auth/profile`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  getDashboardProfile = flow(function* (this: AuthStore) {
    const url = `${Configs.API_URL}/v1/auth/dashboard`;
    try {
      const result: any = yield axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  });

  getDashboardTransactions = flow(function* (
    this: AuthStore,
    page: number,
    pageSize: number,
    types: string
  ) {
    let url = `${Configs.API_URL}/v1/auth/dashboard/transactions?page=${page}&limit=${pageSize}&types=${types}`;

    try {
      const result: any = yield axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      const { data: transactions, pagination } = result.data;
      return { transactions, pagination };
    } catch (e) {
      return errorHandling(e);
    }
  });

  logout(this: AuthStore) {
    localStorage.removeItem("geda-token");
    this.user.set(null);
    return;
  }
}

export const STORE_AUTH = "authStore";
