export const referralList = [
  {
      "user_name": "USER_NAME26",
      "relationship": "Direct",
      "acquired_date": "March 10, 2024 00:00:00",
      "generated_bonus": 0.0261,
      "user_avatar": "https://i.pravatar.cc/300?img=1"
  },
  {
      "user_name": "USER_NAME92",
      "relationship": "Direct",
      "acquired_date": "August 16, 2024 00:00:00",
      "generated_bonus": 0.0415,
      "user_avatar": "https://i.pravatar.cc/300?img=2"
  },
//   {
//       "user_name": "USER_NAME94",
//       "relationship": "Direct",
//       "acquired_date": "May 04, 2024 00:00:00",
//       "generated_bonus": 0.0361,
//       "user_avatar": "https://i.pravatar.cc/300?img=3"
//   },
//   {
//       "user_name": "USER_NAME84",
//       "relationship": "Associate",
//       "acquired_date": "September 22, 2024 00:00:00",
//       "generated_bonus": 0.0479,
//       "user_avatar": "https://i.pravatar.cc/300?img=4"
//   },
//   {
//       "user_name": "USER_NAME98",
//       "relationship": "Direct",
//       "acquired_date": "June 13, 2024 00:00:00",
//       "generated_bonus": 0.0111,
//       "user_avatar": "https://i.pravatar.cc/300?img=5"
//   },
]

export const user_referral = {
    userName: "GEDA_USER",
    userAvatar: "https://i.pravatar.cc/300?img=11",
    nextLevelTarget: 30,
    totalReferrals: 26,
    totalClaimedBonus: 300.0261,
    claimableBonus: 3.0261,
    referralLevel: 3,
    referralList: referralList,
    referralCode: "WX2ND7D",
}

export const referralTransactionList = [
    {
        "user_total": 17,
        "date": "June 22, 2023",
        "total_claimed_reward": 1.2937,
        "total_earned": 1.2937,
        "details": [
            {
                "user_name": "James",
                "date": "June 22, 2023",
                "claimed_reward": 0.0739,
                "earned": 0.0739
            },
            {
                "user_name": "Mary",
                "date": "June 22, 2023",
                "claimed_reward": 0.0792,
                "earned": 0.0792
            },
            {
                "user_name": "Robert",
                "date": "June 22, 2023",
                "claimed_reward": 0.0675,
                "earned": 0.0675
            },
            {
                "user_name": "Patricia",
                "date": "June 22, 2023",
                "claimed_reward": 0.0734,
                "earned": 0.0734
            },
            {
                "user_name": "John",
                "date": "June 22, 2023",
                "claimed_reward": 0.0577,
                "earned": 0.0577
            },
            {
                "user_name": "Jennifer",
                "date": "June 22, 2023",
                "claimed_reward": 0.0944,
                "earned": 0.0944
            },
            {
                "user_name": "Michael",
                "date": "June 22, 2023",
                "claimed_reward": 0.0897,
                "earned": 0.0897
            },
            {
                "user_name": "Linda",
                "date": "June 22, 2023",
                "claimed_reward": 0.0983,
                "earned": 0.0983
            },
            {
                "user_name": "William",
                "date": "June 22, 2023",
                "claimed_reward": 0.0736,
                "earned": 0.0736
            },
            {
                "user_name": "Elizabeth",
                "date": "June 22, 2023",
                "claimed_reward": 0.0522,
                "earned": 0.0522
            },
            {
                "user_name": "David",
                "date": "June 22, 2023",
                "claimed_reward": 0.0749,
                "earned": 0.0749
            },
            {
                "user_name": "Barbara",
                "date": "June 22, 2023",
                "claimed_reward": 0.0688,
                "earned": 0.0688
            },
            {
                "user_name": "Richard",
                "date": "June 22, 2023",
                "claimed_reward": 0.0887,
                "earned": 0.0887
            },
            {
                "user_name": "Susan",
                "date": "June 22, 2023",
                "claimed_reward": 0.0581,
                "earned": 0.0581
            },
            {
                "user_name": "Joseph",
                "date": "June 22, 2023",
                "claimed_reward": 0.0765,
                "earned": 0.0765
            },
            {
                "user_name": "Jessica",
                "date": "June 22, 2023",
                "claimed_reward": 0.0735,
                "earned": 0.0735
            },
            {
                "user_name": "Thomas",
                "date": "June 22, 2023",
                "claimed_reward": 0.0718,
                "earned": 0.0718
            }
        ]
    },
    {
        "user_total": 17,
        "date": "July 15, 2023",
        "total_claimed_reward": 1.2791,
        "total_earned": 1.2791,
        "details": [
            {
                "user_name": "Patricia",
                "date": "July 15, 2023",
                "claimed_reward": 0.0717,
                "earned": 0.0717
            },
            {
                "user_name": "John",
                "date": "July 15, 2023",
                "claimed_reward": 0.0984,
                "earned": 0.0984
            },
            {
                "user_name": "Jennifer",
                "date": "July 15, 2023",
                "claimed_reward": 0.0566,
                "earned": 0.0566
            }
        ]
    },
    {
        "user_total": 17,
        "date": "August 10, 2023",
        "total_claimed_reward": 1.1722,
        "total_earned": 1.1722,
        "details": [
            {
                "user_name": "Michael",
                "date": "August 10, 2023",
                "claimed_reward": 0.0600,
                "earned": 0.0600
            },
            {
                "user_name": "Linda",
                "date": "August 10, 2023",
                "claimed_reward": 0.0682,
                "earned": 0.0682
            },
            {
                "user_name": "William",
                "date": "August 10, 2023",
                "claimed_reward": 0.0650,
                "earned": 0.0650
            }
        ]
    }
]

