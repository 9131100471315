import { useEffect, useState } from "react";
import { IoRefresh } from "react-icons/io5";
import { LoadingIconV2 } from "../loading-icon-v2";
import { formatLastUpdateDateTIme } from "src/utils";
import { IPaginationResponse } from "src/types/dashboard";
import { Pagination } from "../pagination";
import { ReactComponent as ArrowUp } from "src/svg-icons/white-arrow-up.svg";
import { ReactComponent as ArrowDown } from "src/svg-icons/white-arrow-down.svg";
import { ReactComponent as GStar } from 'src/svg-icons/gstar.svg';
import { ReactComponent as GrayReferralIcon } from "src/svg-icons/gray-friends.svg";

import { useIsPcScreenWidth } from "src/utils/rwd";

import clsx from "clsx";

interface iDashboardTransactionHistoryReferralTab {
  dashboardTransactions: any[];
  dashboardTransactionsReceivedDate: Date | null;
  getDashboardTransactionsCb: () => void;
  page: number;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  paginationResponse: IPaginationResponse;
}

export const DashboardTransactionHistoryReferralTab = (
  props: iDashboardTransactionHistoryReferralTab
) => {
  const {
    dashboardTransactions,
    dashboardTransactionsReceivedDate,
    getDashboardTransactionsCb,
    page,
    pageSize,
    setPage,
    setPageSize,
    paginationResponse,
  } = props;

  const pageSizeOptions = [0, 5, 10, 15, 20];
  const [formattedTransactions, setFormattedTransactions] = useState<any[]>([]);
  const isPC = useIsPcScreenWidth();

  useEffect(() => {
    if (dashboardTransactions) {
      const list = dashboardTransactions.map((item: any) => ({
        ...item,
        isExpand: false,
      }));
      setFormattedTransactions(list);
    }
  }, [dashboardTransactions]);

  return (
    <>
      {!dashboardTransactions && (
        <div className="flex items-center justify-center">
          <LoadingIconV2 />
        </div>
      )}

      {dashboardTransactions && (
        <div>
          <div className="flex flex-row items-center mb-space-6 ">
            <div className="flex-1">
              {dashboardTransactionsReceivedDate && (
                <p className="option-label text-grey-400">
                  Last updated{" "}
                  {formatLastUpdateDateTIme(dashboardTransactionsReceivedDate)}
                </p>
              )}
            </div>
            <div
              className="flex items-center cursor-pointer"
              onClick={() => getDashboardTransactionsCb()}>
              <p className="option-label text-grey-600 mr-space-1">Refresh</p>
              <IoRefresh className="w-[20px] h-[20px] text-grey-600" />
            </div>
            <p className="option-label text-grey-600 px-space-2">|</p>
            <div className="flex items-center">
              <p className="option-label text-grey-600 mr-space-1">
                Show on page
              </p>
              <select
                className="select-box bg-transparent text-grey-600 cursor-pointer"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}>
                {pageSizeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option === 0 ? "All" : option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex flex-col gap-2 justify-between min-h-[200px] md:px-4">
            <div className="grid grid-cols-4 px-4">
              <div className="min-w-[80px] md:min-w-[178px]">
                <p className="text-[12px] md:text-[14px]">User Name</p>
              </div>
              <div className="min-w-[80px] md:min-w-[104px]">
                <p className="text-[12px] md:text-[14px]">Date</p>
              </div>
              <div className="min-w-[80px] md:min-w-[104px] text-center">
                <p className="text-[12px] md:text-[14px]">{`Claimed${isPC ? ' Reward' : ''}`}</p>
              </div>
              <div className="min-w-[80px] md:min-w-[104px] text-center">
                <p className="text-[12px] md:text-[14px]">{`${isPC ? ' Total' : ''} Earned`}</p>
              </div>
            </div>
            {formattedTransactions.map((item: any, i: number) => {
              return (
                <>
                  <div
                    key={i}
                    className={clsx(
                      "grid grid-cols-4 cursor-pointer relative py-2 px-4",
                      "bg-[#5b5b5b] opacity-50"
                    )}
                    onClick={() => {
                      const newItem = { ...item, isExpand: !item.isExpand };
                      setFormattedTransactions((prevTransactions) =>
                        prevTransactions.map((transaction, index) =>
                          index === i ? newItem : transaction
                        )
                      );
                    }}
                  >
                    <div className="min-w-[80px] md:min-w-[178px] gap-1 flex justify-start items-center">
                      <GrayReferralIcon className="size-3 md:size-[14px]" />
                      <p className="text-[12px] md:text-[14px]">{item.details?.length}</p>
                    </div>
                    <div className="min-w-[80px] md:min-w-[104px]">
                      <p className="text-[12px] md:text-[14px]">{item.date}</p>
                    </div>
                    <div className="min-w-[80px] md:min-w-[104px] flex justify-center items-center">
                      <GStar className="size-[14px]" />
                      <p className="text-[12px] md:text-[14px]">{item.total_claimed_reward}</p>
                    </div>
                    <div className="min-w-[80px] md:min-w-[104px] flex justify-center items-center">
                      <GStar className="size-[14px]" />
                      <p className="text-[12px] md:text-[14px]">{item.total_earned}</p>
                    </div>
                    <div className="absolute top-[50%] translate-y-[-50%] right-2">
                      {item.isExpand ? (<ArrowUp />) : (<ArrowDown />)}
                    </div>
                  </div>
                  <div
                    className={clsx(
                      "px-4",
                      "transition-all duration-500 ease-in-out overflow-hidden",
                      item.isExpand ? "max-h-[1000px]" : "max-h-0"
                    )}
                  >
                    {item.details?.map((referral: any, j: number) => {
                      return (
                        <div className="grid grid-cols-4" key={j}>
                          <div className="min-w-[80px] md:min-w-[178px]">
                            <p className="text-[12px] md:text-[14px]">{referral.user_name}</p>
                          </div>
                          <div className="min-w-[80px] md:min-w-[104px]">
                            <p className="text-[12px] md:text-[14px]">{referral.date}</p>
                          </div>
                          <div className="min-w-[80px] md:min-w-[104px] flex justify-center items-center">
                            <GStar className="size-[14px]" />
                            <p className="text-[12px] md:text-[14px]">{referral.claimed_reward}</p>
                          </div>
                          <div className="min-w-[80px] md:min-w-[104px] flex justify-center items-center">
                            <GStar className="size-[14px]" />
                            <p className="text-[12px] md:text-[14px]">{referral.earned}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
            )})}
            <Pagination
              page={page}
              setPage={setPage}
              paginationResponse={paginationResponse}
            />
          </div>
        </div>
      )}
    </>
  );
};
