import { useWindowSize } from "@uidotdev/usehooks";
import { useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { IDashboardProfile } from "src/types/dashboard";
import { formatDollar } from "src/utils";
import { DashboardInventoryConfirmCase } from "./dashboard/dashboard-inventory-confirm-case";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { useMarketplaceStore } from "src/stores";
import { LoadingIconV2 } from "./loading-icon-v2";
import { useAuth } from "./auth-context";
import { ISellItem } from "src/types/mysteryBox";

interface IModalInventoryCaseSellAll {
  onCancel: () => void;
  onComplete: () => void;
  dashboardProfile: IDashboardProfile;
  sellItems: ISellItem[];
}

export const ModalInventoryCaseSellAll = (
  props: IModalInventoryCaseSellAll
) => {
  const { onCancel, onComplete, dashboardProfile, sellItems } = props;

  const [step, setStep] = useState<string>("confirm"); // confirm, complete
  const [loading, setLoading] = useState<boolean>(false);

  const { sellItemsById: sellWeapons } = useMarketplaceStore();
  const { getDashboardProfileCb } = useAuth();

  const size: any = useWindowSize();
  const isResponsive = isMobile || size?.width < 1080;

  const handleSell = async () => {
    if (sellItems.length === 0) {
      return openWarningNotification("No items to sell");
    }

    if (loading) return;
    setLoading(true);

    let formattedSellItems = sellItems.map((sellItem: any) => {
      return sellItem?._id;
    });

    let failedBatches = 0;

    // Process all batches, tracking failures
    while (formattedSellItems.length > 0) {
      const currentBatch = formattedSellItems.slice(0, 100);
      const result = await sellWeapons({
        ids: currentBatch,
      });

      if (!result || typeof result === "string") {
        failedBatches++;
        openWarningNotification(
          `Failed to sell batch of ${currentBatch.length} items`
        );
      }

      formattedSellItems = formattedSellItems.slice(100);
    }

    setLoading(false);

    if (failedBatches > 0) {
      openWarningNotification(
        `Completed with ${failedBatches} failed batches. Please check your transaction history.`
      );
    } else {
      openSuccessNotification(
        "Sell complete, the transfer may take some time."
      );
    }

    getDashboardProfileCb();
    setStep("complete");
  };

  const weaponsPrice = useMemo(() => {
    return sellItems.length > 0
      ? sellItems.reduce(
          (a, v: any) => (a = a + (v?.item?.weaponPrice || 0)),
          0
        )
      : 0;
  }, [sellItems]);

  return (
    <div className="w-screen h-screen z-[999] fixed top-0 left-0 flex items-center justify-center">
      <div
        className="gap-space-2 relative z-[999] w-[320px] h-[568px] md:w-[640px] md:h-[590px]  items-start"
        style={{
          backgroundImage: `url("/assets/v4/${
            !isResponsive ? "receipt-bg" : "modal-mobile-bg"
          }.png")`,
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-row items-center p-space-7 py-space-7">
          <h2 className="text-xxl md:text-xxxl text-grey-400 flex-1 mt-space-4 md:mt-space-8">
            {step === "confirm" ? "Sell Weapons" : ""}
            {step === "complete" ? "Sell Complete" : ""}
          </h2>
          <img
            src="/assets/v4/modal-close.png"
            alt="modal-close"
            className="w-[30px] h-[30px] md:w-[56px] md:h-[56px] cursor-pointer"
            onClick={() => {
              if (step === "complete") {
                onComplete();
              } else {
                onCancel();
              }
            }}
          />
        </div>

        {/* Divider */}
        <div className="w-full h-[1px] bg-grey-800" />

        <div className="px-space-8 h-[350px] py-space-4 flex flex-col gap-space-4 overflow-hidden overflow-y-auto scrollbar-hidden scrollbar-hide">
          {step === "confirm" && (
            <div className="flex flex-col md:flex-row gap-space-2">
              {/* Transaction summary  */}
              <div className="w-full md:w-[230px] max-w-full md:max-w-[230px] py-space-4">
                <div className="flex flex-col gap-space-4">
                  <p className="text-l leading-[19px] font-[700] blender-medium text-grey-100">
                    Transaction summary
                  </p>
                  {sellItems.length > 1 && (
                    <div className="flex flex-row items-center justify-between">
                      <p className="small-content text-black-100">
                        Items selected
                      </p>
                      <p className="text-l leading-[19px] text-grey-100 font-[500] blender-medium">
                        {sellItems.length}
                      </p>
                    </div>
                  )}
                  <div className="flex flex-row items-center justify-between">
                    <p className="small-content text-black-100">
                      Sell value (GSTAR)
                    </p>
                    <div className="flex flex-row items-center gap-space-1">
                      <img
                        src="/assets/v4/geda-star.png"
                        alt="geda-star"
                        className="w-[20px] h-[20px]"
                      />
                      <p className="text-l leading-[19px] text-grey-100 font-[500] blender-medium">
                        {formatDollar(weaponsPrice)}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-between">
                    <p className="small-content text-black-100">Deposit to</p>
                    <p className="text-l leading-[19px] text-grey-100 font-[500] blender-medium">
                      {dashboardProfile?.username}
                    </p>
                  </div>
                </div>
              </div>

              {/* Asset list */}
              <div className="flex-1 rounded-s px-space-2 py-space-4 bg-black-800">
                <div className="flex flex-col gap-space-2">
                  <p className="text-l leading-[19px] font-[700] blender-medium text-black-100">
                    Confirm weapons
                  </p>
                  <div className="grid grid-cols-2 gap-space-2">
                    {sellItems.length > 0 &&
                      sellItems.map((item: any, i: number) => {
                        return (
                          <DashboardInventoryConfirmCase
                            item={{
                              item: item,
                            }}
                            key={i}
                          />
                        );
                      })}
                  </div>
                  <div>
                    <p className="small-content font-[500] blender-medium text-native-red mb-space-1">
                      *Disclaimer*
                    </p>
                    <p className="small-content font-[500] blender-medium text-grey-600">
                      Withdrawal process will require time and may not reflect
                      in real-time. Assets transferred out to external wallets
                      are final and can not be reversed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {step === "complete" && (
            <div className="flex flex-col justify-center items-center gap-space-4 h-full">
              <h2 className="text-xxl md:text-xxxl text-grey-100">
                Request complete
              </h2>
              <p className="text-l leading-[24px] font-[400] blender-medium text-native-yellow text-center">
                Sell complete, the transfer may take some time. You may check
                the status of the sell from transactions history located on user
                dashboard
              </p>
            </div>
          )}
        </div>

        {/* Divider */}
        <div className="w-full h-[1px] bg-grey-800" />

        {/* Confirmation */}
        <div className="flex justify-center items-center py-space-4 px-space-4">
          {step === "confirm" && (
            <button
              className="border border-orange-600 w-[400px] h-[47px] bg-black-800 flex justify-center items-center rounded-xs blender-medium text-orange-600 font-[500] text-xs md:text-m"
              onClick={(e) => handleSell()}
            >
              {loading ? <LoadingIconV2 /> : "Sell All"}
            </button>
          )}

          {step === "complete" && (
            <button
              className="border border-orange-600 w-[400px] h-[47px] bg-black-800 flex justify-center items-center rounded-xs blender-medium text-orange-600 font-[500] text-xs md:text-m"
              onClick={(e) => onComplete()}
            >
              <span>Return to dashboard</span>
              <img
                src="/assets/v4/dashboard.png"
                alt="Dashboard"
                className="ml-space-2 w-[18px] h-[18px] md:w-[22px] md:h-[22px]"
              />
            </button>
          )}
        </div>
      </div>

      {/* Overlay */}
      <div className="bg-black-800/60 w-full h-full left-0 absolute"></div>
    </div>
  );
};
