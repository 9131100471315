import React, { useState, useContext } from "react";
import { useSpring, animated } from "react-spring/web";
import { history } from "src/stores";
import { openWarningNotification } from "src/components";
import { ModalBalance } from "src/components/modal-balance";
import { ModalContainer } from "src/components/modal/modal-container";
import { AuthStore } from "src/stores/auth";
import { MobileNavBalance } from "./mobile-nav-balance";
import { MobileNavMenuItem } from "./mobile-nav-menu-item";
import { RoundButton } from "../round-button";
import { SlWallet } from "react-icons/sl";
import { useLocation } from "react-router";
import { useAuth } from "../auth-context";
import { Label } from "../label";
import { InboxContext } from "../inbox-provider";
import { InboxIcon } from "../inbox-icon";
import { NavLanguageList } from "./nav-language-list";
import { PiTranslate } from "react-icons/pi";
import { IoIosArrowDown } from "react-icons/io";
import { useLanguage } from "src/hooks/useLanguage";
import { Token } from "src/types/currency";
import { t } from "i18next";
import { useWallet } from "src/hooks/useWallet";

interface iMobileNavV2Type {
  withBg: boolean;
  openDrawer: boolean;
  setOpenDrawer: (value: boolean) => void;
  openDrawerCounter: number;
  setOpenDrawerCounter: (value: number) => void;
}

export const MobileNavV2 = (props: iMobileNavV2Type) => {
  const {
    withBg = true,
    openDrawer = false,
    setOpenDrawer,
    openDrawerCounter = 0,
    setOpenDrawerCounter,
  } = props;
  const [beforeActivePage, setBeforeActivePage] = useState<string>();
  const [balanceModal, setBalanceModal] = useState(false);
  const { setDepositModal, setDepositCurrency, depositModal, depositCurrency } =
    useWallet();

  const {
    languageLabel,
    handleChangeLanguage,
    openLanguageList,
    setOpenLanguageList,
  } = useLanguage();
  const location = useLocation();
  const {
    loggedIn,
    setLoggedIn,
    setUserInfo,
    setOpenLoginModal,
    dashboardData,
  } = useAuth();

  const authStore = new AuthStore();
  const { unread, setIsInboxOpen } = useContext(InboxContext);

  const isActive = (name: string) => {
    if (beforeActivePage) {
      if (beforeActivePage.indexOf(name) > -1) {
        return true;
      }
    } else {
      return location?.pathname.indexOf(name) > -1;
    }
  };

  const handleLogout = () => {
    authStore.logout();
    setLoggedIn(false);
    setUserInfo(null);
  };

  const handleLogin = () => {
    setOpenLoginModal(true);
  };

  const handleTopUp = () => {
    if (!loggedIn)
      return openWarningNotification(t("message.please-login-first"));
    setDepositModal(true);
    setDepositCurrency(Token.GSTAR);
  };

  const drawerSpring1 = useSpring({
    display: openDrawer ? "block" : "none",
    from: { display: openDrawer ? "block" : "block" },
    delay: openDrawer ? 0 : openDrawerCounter > 0 ? 500 : 0,
    reset: true,
  });

  const drawerSpring2 = useSpring({
    opacity: openDrawer ? 1 : 0,
    config: {
      duration: 490,
    },
    delay: 10,
    reset: true,
  });

  return (
    <div className="mobile">
      <div className="relative h-[40px]"></div>
      <div
        className={`fixed left-0 top-0 w-full flex flex-row z-[100] items-center justify-between ${
          withBg ? "bg-black-400/60" : "bg-transparent"
        }`}
        style={{ backdropFilter: "blur(3px)" }}
      >
        <div>
          <div
            className="p-space-2 cursor-pointer"
            onClick={() => {
              setOpenDrawer(false);

              setTimeout(
                () => {
                  history.push("/");
                },
                openDrawer ? 300 : 0
              );
            }}
          >
            <img
              src="/assets/v4/mobile-nav-geda.png"
              alt="mobile-logo"
              className="w-[24px] h-[24px]"
            />
          </div>
        </div>
        <div className="relative flex-1">
          <div
            className="absolute top-[50%] right-0 transform translate-y-[-50%] transition-all duration-500"
            style={{
              opacity: openDrawer ? 1 : 0,
            }}
          >
            <div className="z-[5] flex flex-row items-center justify-end gap-space-2 pl-space-2 pr-space-4">
              <RoundButton
                action={() => setOpenDrawer(false)}
                icon="/assets/v4/mobile-nav-close.png"
                sxStyle={{ padding: "4px" }}
                iconSize="14px"
              />
            </div>
          </div>
          <div
            className="absolute top-[50%] transform translate-y-[-50%] transition-all duration-500"
            style={{
              opacity: openDrawer ? 0 : 1,
              right: openDrawer ? "-100vw" : "0px",
            }}
          >
            <div className="z-[5] flex flex-row items-center justify-end gap-space-2 pl-space-2 pr-space-4">
              {loggedIn && (
                <>
                  <div
                    className="px-space-2 py-[6px] rounded-xs cursor-pointer mx-auto flex items-center justify-center flex-row gap-space-1 backdrop-blur-[4px] bg-native-green"
                    onClick={() => handleTopUp()}
                  >
                    <SlWallet className="w-[12px] h-[12px] text-white" />
                    <p className="text-s leading-[10px] font-[500] blender-medium">
                      Top up
                    </p>
                  </div>

                  <MobileNavBalance
                    dashboardData={dashboardData}
                    setBalanceModal={setBalanceModal}
                  />

                  <RoundButton
                    action={() => history.push("/dashboard")}
                    icon="/assets/v4/dashboard.png"
                    sxStyle={{ padding: "4px" }}
                    iconSize="14px"
                  />

                  <RoundButton
                    action={() => handleLogout()}
                    icon="/assets/v4/logout.png"
                    sxStyle={{ padding: "4px" }}
                    iconSize="14px"
                  />
                </>
              )}

              {!loggedIn && (
                <RoundButton
                  name="Connect Wallet"
                  action={() => handleLogin()}
                  icon="/assets/v4/nav_wallet.png"
                  sxStyle={{ padding: "1px 8px" }}
                  nameSx="small-content text-grey-800"
                  iconSize="20px"
                  iconInLeft={true}
                />
              )}
              <RoundButton
                action={() => setIsInboxOpen((prev) => !prev)}
                sxStyle={{ padding: "4px" }}
              >
                <InboxIcon
                  className="w-[14px] h-[14px]"
                  unreadClass="w-[12px] h-[12px] translate-x-[6px] -translate-y-[6px]"
                  unread={unread}
                />
              </RoundButton>

              <RoundButton
                action={() => {
                  setOpenDrawerCounter(openDrawerCounter + 1);
                  setOpenDrawer(true);
                }}
                icon="/assets/v4/mobile-nav-burger-bar.png"
                sxStyle={{ padding: "4px" }}
                iconSize="14px"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Drawer */}
      <animated.div
        className="fixed top-[40px] left-[17.5vw] w-[82.5vw] h-[calc(100%-40px)] z-[1010] transition-opacity duration-500"
        style={{
          ...drawerSpring1,
          ...drawerSpring2,
        }}
      >
        <div
          className="absolute top-0 left-[16px] w-full h-full bg-[#19191999]"
          style={{
            backdropFilter: "blur(3px)",
          }}
        />
        <div
          className="absolute top-0 left-[16px] w-[1px] h-full"
          style={{
            background:
              "linear-gradient(236.17deg, rgba(254, 82, 25, 0) 0%, rgba(102, 63, 227, 0.966976) 10.03%, #FE5219 20.06%, #613EEA 30.1%, rgba(254, 82, 25, 0) 40.13%)",
          }}
        />
        <div
          className="absolute bottom-0 left-0 w-full h-[2px]"
          style={{
            background:
              "linear-gradient(236.17deg, rgba(254, 82, 25, 0) 20%, rgba(102, 63, 227, 0.966976) 30.03%, #FE5219 40.06%, #613EEA 50.1%, rgba(254, 82, 25, 0) 60.13%)",
          }}
        />
        <div className="h-full flex flex-col">
          {loggedIn && (
            <div className="relative ml-space-4">
              <div
                className="pt-space-4 pl-space-4 pr-space-4"
                style={{
                  background:
                    "radial-gradient(50% 80.81% at 50% 100%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 0.01%, rgba(255, 255, 255, 0) 100%)",
                }}
              >
                <div
                  className="flex flex-row items-center gap-space-2 cursor-pointer"
                  onClick={() => history.push("/dashboard")}
                >
                  <RoundButton
                    icon="/assets/v4/dashboard.png"
                    sxStyle={{ padding: "7px" }}
                    iconSize="20px"
                  />
                  <p className="flex-1 text-l text-grey-400 leading-[19px]">
                    Dashboard
                  </p>
                </div>
                <div
                  className="w-full h-[1px] mt-space-4"
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(254, 254, 254, 0) 0%, #FEFEFE 62.5%, rgba(254, 254, 254, 0) 100%)",
                  }}
                ></div>
              </div>
            </div>
          )}

          <div className="h-full flex flex-col pl-space-4 overflow-auto scrollbar-hidden">
            {openLanguageList && (
              <div className="absolute inset-0 left-[17px] bg-[#191919] bg-opacity-60 backdrop-blur-sm z-[1000]"></div>
            )}
            <div className="flex-1">
              <div className="h-full flex flex-col">
                <div className="flex-1 flex flex-col items-start gap-space-4 p-space-4">
                  <MobileNavMenuItem
                    icon="/assets/v4/earn.svg"
                    name="Spin & Earn"
                    page={"/airdrop"}
                    isActive={isActive("/airdrop")}
                    setBeforePage={setBeforeActivePage}
                    setOpenDrawer={setOpenDrawer}
                  />
                  <MobileNavMenuItem
                    icon="/assets/v4/watch.svg"
                    name="Watch & Predict"
                    page={"/prediction"}
                    isActive={isActive("/prediction")}
                    setBeforePage={setBeforeActivePage}
                    setOpenDrawer={setOpenDrawer}
                  />
                  {/* <MobileNavMenuItem
                    icon="/assets/v4/mp.svg"
                    name="Marketplace"
                    page={"/marketplace/sell"}
                    isActive={isActive("/marketplace")}
                    setBeforePage={setBeforeActivePage}
                    setOpenDrawer={setOpenDrawer}
                  /> */}
                  <MobileNavMenuItem
                    icon="/assets/v4/reward.png"
                    name="Case Open"
                    page={"/mystery-box"}
                    isActive={isActive("/mystery-box")}
                    setBeforePage={setBeforeActivePage}
                    setOpenDrawer={setOpenDrawer}
                  />

                  <div className="relative">
                    <MobileNavMenuItem
                      iconProps={"FaGuilded"}
                      name="Guild"
                      setBeforePage={setBeforeActivePage}
                      setOpenDrawer={setOpenDrawer}
                    />
                    <Label name="Soon" type="coming-soon" />
                  </div>

                  <div className="relative">
                    <MobileNavMenuItem
                      iconProps={"MdSportsEsports"}
                      name="Esports"
                      setBeforePage={setBeforeActivePage}
                      setOpenDrawer={setOpenDrawer}
                    />
                    <Label name="Soon" type="coming-soon" />
                  </div>
                </div>
                <div className="relative p-space-4">
                  <div
                    className="flex flex-row items-center cursor-pointer"
                    onClick={() => openWarningNotification("Coming Soon!")}
                  >
                    <p className="text-l text-grey-400 leading-[19px]">
                      Referral
                    </p>
                  </div>
                  <div
                    className="w-full h-[1px] mt-space-4 relative z-[1001]"
                    style={{
                      background:
                        "linear-gradient(90deg, rgba(254, 254, 254, 0) 0%, #FEFEFE 62.5%, rgba(254, 254, 254, 0) 100%)",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className={`relative flex mb-space-6 ml-space-4 z-[1001]`}>
              <button
                onClick={() => setOpenLanguageList(!openLanguageList)}
                className="flex flex-row items-center justify-center cursor-pointer py-space-2 px-space-4 bg-black-600 border-[1px] border-solid border-black-200 rounded-full gap-space-1 text-l font-[700] leading-[19px] blender-medium normal-case"
              >
                {languageLabel}
                {openLanguageList ? <IoIosArrowDown /> : <PiTranslate />}
              </button>
              {openLanguageList && (
                <NavLanguageList
                  handleChangeLanguage={handleChangeLanguage}
                  mobile={true}
                />
              )}
            </div>
          </div>
          <div className="relative ml-space-4">
            <div
              className="pb-space-4 pl-space-4 pr-space-4"
              style={{
                background:
                  "radial-gradient(50% 80.81% at 50% 0%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 0.01%, rgba(255, 255, 255, 0) 100%)",
              }}
            >
              <div
                className="w-full h-[1px] mb-space-4"
                style={{
                  background:
                    "linear-gradient(90deg, rgba(254, 254, 254, 0) 0%, #FEFEFE 62.5%, rgba(254, 254, 254, 0) 100%)",
                }}
              ></div>
              <div className="flex flex-row items-center justify-end">
                <div className="flex-1">
                  <div className="w-[105px] h-[40px] bg-[url('/assets/v4/mobile-nav-language.png')] bg-no-repeat bg-cover" />
                </div>
                <RoundButton
                  action={() => openWarningNotification("Coming Soon!")}
                  icon="/assets/v4/mobile-nav-setting.png"
                  iconSize="20px"
                />
              </div>
            </div>
          </div>
        </div>
      </animated.div>

      {/* Modal */}
      {balanceModal && (
        <ModalBalance
          onCancel={() => setBalanceModal(false)}
          data={dashboardData}
          setDepositModal={setDepositModal}
          setDepositCurrency={setDepositCurrency}
        />
      )}

      {depositModal && (
        <ModalContainer
          setIsModalOpen={setDepositModal}
          type={"deposit"}
          extra={{
            depositCurrency: depositCurrency,
          }}
        />
      )}
    </div>
  );
};
