import { TbDeviceGamepad3 } from "react-icons/tb";
import {
  DISPLAY_MODE_CASE,
  DISPLAY_MODE_MAX_CASE,
  DISPLAY_MODE_SPIN,
  DISPLAY_MODE_GDD_CASE,
} from "src/config/dashboard";
import { ISellItem } from "src/types/mysteryBox";

const tabButtonStyle = `flex flex-col sm:flex-row items-center justify-center gap-space-0 sm:gap-space-1 text-s sm:text-l leading-[12px] sm:leading-[24px] font-[700] blender-medium normal-case p-space-1 sm:p-space-3 border-0 border-b-[2px] border-solid rounded-[0px] cursor-pointer`;

interface IInventorySwitch {
  dashboardDisplayMode: string;
  setDashboardDisplayMode: (val: string) => void;
  setSpins: (val: [] | null) => void;
  setWeapons: (val: ISellItem[] | null) => void;
}

export const InventorySwitch = (props: IInventorySwitch) => {
  const {
    dashboardDisplayMode,
    setDashboardDisplayMode,
    setSpins,
    setWeapons,
  } = props;
  return (
    <div className="grid grid-cols-4 w-full border-0 border-b-[1px] border-solid border-black-200 rounded-tl-s rounded-tr-s overflow-hidden backdrop-blur-[10px]">
      <div
        className={`${tabButtonStyle} ${
          dashboardDisplayMode === DISPLAY_MODE_SPIN
            ? "text-orange-800 bg-black-600 border-orange-800"
            : "text-grey-100 bg-[#00000066] border-transparent"
        }`}
        onClick={() => {
          setSpins(null);
          setDashboardDisplayMode(DISPLAY_MODE_SPIN);
        }}
      >
        <TbDeviceGamepad3 className="w-[14px] h-[14px] text-orange-800" />
        <span>Spin</span>
      </div>
      <div
        className={`${tabButtonStyle} ${
          dashboardDisplayMode === DISPLAY_MODE_CASE
            ? "text-orange-800 bg-black-600 border-orange-800"
            : "text-grey-100 bg-[#00000066] border-transparent"
        }`}
        onClick={() => {
          setWeapons(null);
          setDashboardDisplayMode(DISPLAY_MODE_CASE);
        }}
      >
        <img
          src="/assets/v4/geda-star.png"
          alt="geda-star"
          className="w-[20px] h-[20px]"
        />
        <span>GSTAR Case</span>
      </div>

      <div
        className={`${tabButtonStyle} ${
          dashboardDisplayMode === DISPLAY_MODE_MAX_CASE
            ? "text-orange-800 bg-black-600 border-orange-800"
            : "text-grey-100 bg-[#00000066] border-transparent"
        }`}
        onClick={() => {
          setWeapons(null);
          setDashboardDisplayMode(DISPLAY_MODE_MAX_CASE);
        }}
      >
        <img
          src="/assets/v4/case-opening-matr1x-coin-icon.png"
          alt="geda-star"
          className="w-[20px] h-[20px]"
        />
        <span>MAX Case</span>
      </div>

      <div
        className={`${tabButtonStyle} ${
          dashboardDisplayMode === DISPLAY_MODE_GDD_CASE
            ? "text-orange-800 bg-black-600 border-orange-800"
            : "text-grey-100 bg-[#00000066] border-transparent"
        }`}
        onClick={() => {
          setWeapons(null);
          setDashboardDisplayMode(DISPLAY_MODE_GDD_CASE);
        }}
      >
        <img
          src="/assets/v4/gdd.png"
          alt="geda-star"
          className="w-[20px] h-[20px]"
        />
        <span>GDD Case</span>
      </div>
    </div>
  );
};
