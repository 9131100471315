import { IoRefresh } from "react-icons/io5";
import { DashboardTransactionHistoryCommonHeader } from "./dashboard-transaction-history-common-header";
import { DashboardTransactionHistoryCommonRow } from "./dashboard-transaction-history-common-row";
import { LoadingIconV2 } from "../loading-icon-v2";
import { formatLastUpdateDateTIme } from "src/utils";
import { IPaginationResponse } from "src/types/dashboard";
import { Pagination } from "../pagination";
import { useMemo } from "react";

interface iDashboardTransactionHistoryCommonTab {
  dashboardTransactions: any[];
  dashboardTransactionsReceivedDate: Date | null;
  getDashboardTransactionsCb: () => void;
  page: number;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  paginationResponse: IPaginationResponse;
}

export const DashboardTransactionHistoryCommonTab = (
  props: iDashboardTransactionHistoryCommonTab
) => {
  const {
    dashboardTransactions,
    dashboardTransactionsReceivedDate,
    getDashboardTransactionsCb,
    page,
    pageSize,
    setPage,
    setPageSize,
    paginationResponse,
  } = props;

  const formattedTransactions = useMemo(() => {
    if (!dashboardTransactions) return [];
    return dashboardTransactions.filter(
      (dashboardTransaction) =>
        dashboardTransaction.type === "deposit" ||
        dashboardTransaction.type === "deposit-crypto" ||
        dashboardTransaction.type === "p2p-transfer"
    );
  }, [dashboardTransactions]);

  const pageSizeOptions = [0, 5, 10, 15, 20];

  return (
    <>
      {!dashboardTransactions && (
        <div className="flex items-center justify-center">
          <LoadingIconV2 />
        </div>
      )}

      {dashboardTransactions && (
        <div>
          <div className="flex flex-row items-center mb-space-6 ">
            <div className="flex-1">
              {dashboardTransactionsReceivedDate && (
                <p className="option-label text-grey-400">
                  Last updated{" "}
                  {formatLastUpdateDateTIme(dashboardTransactionsReceivedDate)}
                </p>
              )}
            </div>
            <div
              className="flex items-center cursor-pointer"
              onClick={() => getDashboardTransactionsCb()}>
              <p className="option-label text-grey-600 mr-space-1">Refresh</p>
              <IoRefresh className="w-[20px] h-[20px] text-grey-600" />
            </div>
            <p className="option-label text-grey-600 px-space-2">|</p>
            <div className="flex items-center">
              <p className="option-label text-grey-600 mr-space-1">
                Show on page
              </p>
              <select
                className="select-box bg-transparent text-grey-600 cursor-pointer"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}>
                {pageSizeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option === 0 ? "All" : option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex flex-col justify-between min-h-[200px]">
            <table className="w-full">
              <DashboardTransactionHistoryCommonHeader />
              <tbody>
                {formattedTransactions.map((item: any, i: number) => {
                  return (
                    <DashboardTransactionHistoryCommonRow item={item} key={i} />
                  );
                })}
              </tbody>
            </table>
            <Pagination
              page={page}
              setPage={setPage}
              paginationResponse={paginationResponse}
            />
          </div>
        </div>
      )}
    </>
  );
};
