import React, { useState, useEffect, useCallback } from "react";
import { IPaginationResponse } from "src/types/dashboard";
import { TfiArrowCircleLeft, TfiArrowCircleRight } from "react-icons/tfi";
import { useWindowSize } from "@uidotdev/usehooks";

interface iPagination {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  paginationResponse: IPaginationResponse | null;
}

export const Pagination = (props: iPagination) => {
  const { page, setPage, paginationResponse } = props;
  const {
    currentPage = 1,
    totalPages = 1,
    hasNewer = false,
    hasOlder = false,
  } = paginationResponse || {};

  const size = useWindowSize();

  const getMaxPageButtons = useCallback(() => {
    if (!size?.width) return 1;
    if (size.width >= 1024) return 10;
    if (size.width >= 760) return 1;
    if (size.width >= 576) return 3;
    return 1;
  }, [size]);

  const [maxPageButtons, setMaxPageButtons] = useState(getMaxPageButtons);

  useEffect(() => {
    setMaxPageButtons(getMaxPageButtons());
  }, [getMaxPageButtons]);

  useEffect(() => {
    if (page <= totalPages) return;
    setPage(totalPages);
  }, [page, totalPages, setPage]);

  const currentGroup = Math.floor((page - 1) / maxPageButtons);
  const currentGroupStartPage = currentGroup * maxPageButtons + 1;
  const currentGroupEndPage = Math.min(
    currentGroupStartPage + maxPageButtons - 1,
    totalPages
  );
  const hasNextGroup = totalPages > currentGroupEndPage;
  const hasPreviousGroup = currentGroup > 0;

  return (
    <div className="flex justify-between pt-space-4">
      {/* Newer button */}
      <button
        className={`no-bg flex items-center ${
          hasNewer ? "cursor-pointer" : "cursor-not-allowed"
        }`}
        onClick={() => {
          if (hasNewer) {
            setPage(currentPage - 1);
          }
        }}
        disabled={!hasNewer}
      >
        <TfiArrowCircleLeft
          className={`w-[16px] h-[16px] ${
            hasNewer ? "text-orange-800" : "text-grey-800"
          }`}
        />
        <p
          className={`option-label ml-space-1 ${
            hasNewer ? "text-orange-800" : "text-grey-800"
          }`}
        >
          Newer
        </p>
      </button>

      {/* page numbers */}
      <div className="inline-flex items-center space-x-2">
        {/* prev group button */}
        {hasPreviousGroup && totalPages > 1 && (
          <button
            onClick={() => setPage(currentGroupStartPage - 1)}
            className="border-none text-grey-800 hover:text-orange-800"
          >
            &lt;
          </button>
        )}

        {/* page */}
        {Array.from({ length: maxPageButtons }, (_, index) => {
          const pageNumber = currentGroupStartPage + index;
          if (pageNumber > totalPages) return null;
          return (
            <button
              key={pageNumber}
              onClick={() => setPage(pageNumber)}
              className={`border-none ${
                page === pageNumber
                  ? "underline text-grey-100 cursor-pointer"
                  : "text-grey-800 hover:text-orange-800"
              }`}
            >
              {pageNumber}
            </button>
          );
        })}

        {/* next group button */}
        {hasNextGroup && totalPages > 1 && (
          <button
            onClick={() => setPage(currentGroupStartPage + maxPageButtons)}
            className="border-none text-grey-800 hover:text-orange-800"
          >
            &gt;
          </button>
        )}
      </div>

      {/* older button */}
      <button
        className={`no-bg flex items-center ${
          hasOlder ? "cursor-pointer" : "cursor-not-allowed"
        }`}
        onClick={() => {
          if (hasOlder) {
            setPage(currentPage + 1);
          }
        }}
        disabled={!hasOlder}
      >
        <p
          className={`option-label mr-space-1 ${
            hasOlder ? "text-orange-800" : "text-grey-800"
          }`}
        >
          Older
        </p>
        <TfiArrowCircleRight
          className={`w-[16px] h-[16px] ${
            hasOlder ? "text-orange-800" : "text-grey-800"
          }`}
        />
      </button>
    </div>
  );
};
