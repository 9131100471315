import { useState, useEffect } from "react";
import {
  REFERRAL_LEVELS,
  getMainColor,
  getBonus,
} from "src/config/referralLevel";
import { CSSProperties } from "react";
import { ReactComponent as PersonIcon } from "src/svg-icons/white-person.svg";
import { ReactComponent as GStar } from "src/svg-icons/gstar.svg";
import { Marquee } from "src/components/marquee";
import { openSuccessNotification } from "src/components";
import { DISPLAY_MODE_DEFAULT } from "src/config/dashboard";
import { useReferralStore } from "src/stores";
import { useAuth } from "src/components/auth-context";
import clsx from "clsx";

interface iDashboardReferralMainProps {
  setHistoryMode: React.Dispatch<
    React.SetStateAction<"case" | "common" | "referral">
  >;
  setDashboardDisplayMode: (val: string) => void;
}
interface iReferralHistory {
  _id: string;
  user_name: string;
  relationship: string;
  created_at: string;
  amount: number;
  user_avatar: string;
}
interface CustomCSSProperties extends CSSProperties {
  "--level-color"?: string;
}

export const DashboardReferralMain = (props: iDashboardReferralMainProps) => {
  const { setHistoryMode, setDashboardDisplayMode } = props;
  const [referralHistoryList, setReferralHistoryList] = useState<
    iReferralHistory[]
  >([]);
  const [dailyStats, setDailyStats] = useState<any>({});
  const { getReferralStats, getPendingBonus, postClaimBonus } =
    useReferralStore();
  const { userInfo } = useAuth();
  useEffect(() => {
    getReferralStats();
    getPendingBonus().then((result) => {
      // insure the referralList's length is over than 5
      if (
        result.pending_bonuses.length !== 0 &&
        result.pending_bonuses.length < 5
      ) {
        const diff = 5 - result.pending_bonuses.length;
        for (let i = 0; i < diff; i++) {
          result.pending_bonuses.push(result.pending_bonuses[i]);
        }
        setDailyStats(result.daily_stats);
        setReferralHistoryList(result.pending_bonuses);
      }
    });
  }, [getReferralStats, getPendingBonus]);

  const btnStyle = `border-[1px] border-solid border-[#fe5219] rounded-xs cursor-pointer flex items-center justify-center flex-row gap-space-1 backdrop-blur-[4px] blender-medium text-s`;

  return (
    <div className="relative bg-[#19191966] p-0 sm:p-space-4 rounded-s backdrop-blur-[8px]">
      {/* user referral information */}
      <div className="flex">
        {/* user avatar & level */}
        <div className="flex flex-col items-center">
          <div
            className="w-[100px] h-[140px] border-[1px] rounded-[50%] overflow-hidden flex justify-center items-center"
            style={{
              boxShadow: `0 0 2px 1px ${
                userInfo?.referral_level !== undefined
                  ? getMainColor(userInfo.referral_level)
                  : "transparent"
              }`,
              borderColor:
                userInfo?.referral_level !== undefined
                  ? getMainColor(userInfo.referral_level)
                  : "transparent",
            }}
          >
            {/* It should add the user avatar in the API response */}
            <img
              src={"/assets/dashboard/user-default.png"}
              alt="user avatar"
              className="w-[140px] h-[140px] max-w-[unset]"
            />
          </div>
          {/* user level icon */}
          <div
            className="referral-level flex items-center justify-center mt-[-30px]"
            style={
              {
                "--level-color": `${
                  userInfo?.referral_level !== undefined
                    ? getMainColor(userInfo.referral_level)
                    : "transparent"
                }`,
              } as CustomCSSProperties
            }
          >
            <div className="referral-level-dressing-left"></div>
            <img
              src={`assets/dashboard/referral-${userInfo?.referral_level}.png`}
              alt=""
            />
            <div className="referral-level-dressing-right"></div>
          </div>
        </div>
        {/* user referral details */}
        <div className="flex-1 px-2">
          {/* user name */}
          <p className="uppercase">{userInfo?.username}</p>
          {/* user next level */}
          {userInfo?.referral_level !== undefined &&
            userInfo.referral_level + 1 <= REFERRAL_LEVELS.length && (
              <div className="flex justify-end items-center">
                <p className="text-[12px]">Next level:</p>
                <div className="ml-2 flex flex-col items-center">
                  <img
                    src={`assets/dashboard/referral-${
                      userInfo?.referral_level + 1
                    }.png`}
                    alt=""
                    className="w-[18px] h-[18px]"
                  />
                  <p
                    className="mt-1 text-[8px]"
                    style={{
                      color:
                        userInfo?.referral_level !== undefined
                          ? getMainColor(userInfo.referral_level + 1)
                          : "transparent",
                    }}
                  >{`LEVEL ${userInfo?.referral_level + 1}`}</p>
                </div>
              </div>
            )}
          {/* user referral progress */}
          <div className="progress mt-2">
            <div className="progress-bar-top"></div>
            <div
              className="progress-bar my-[1px] p-[1px] h-2 flex items-center rounded-[2px]"
              style={{
                background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
                url('/assets/dashboard/progress-bar-bg.png') 0 90% / cover no-repeat`,
              }}
            >
              <div
                className="progress-bar-inner relative h-[6px] rounded-[2px]"
                style={{
                  width: `${
                    (dailyStats.count / (dailyStats?.nextLevelTarget || 100)) *
                    100
                  }%`,
                  backgroundColor:
                    userInfo?.referral_level !== undefined
                      ? getMainColor(userInfo.referral_level)
                      : "transparent",
                }}
              >
                <div
                  className="floating-dialog absolute bottom-[-48px] w-[max-content] right-0"
                  style={{ transform: `translateX(50%)` }}
                >
                  <div className="dialog-border-up p-1">
                    <p>{`${dailyStats?.count || 0} Referral`}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="progress-bar-bottom"></div>
          </div>
          {/* user referral information */}
          <div className="flex justify-between mt-12">
            <div className="text-left">
              <p className="text-[#fefefe]">Current bonus</p>
              <p className="text-xxml text-orange-800 blender-medium font-[900] uppercase leading-none">
                {`${
                  userInfo?.referral_level !== undefined
                    ? getBonus(userInfo.referral_level)
                    : 0
                } bonus`}
              </p>
            </div>
            <div className="text-right opacity-30">
              {userInfo?.referral_level !== undefined &&
                userInfo.referral_level + 1 <= REFERRAL_LEVELS.length && (
                  <>
                    <p>Upcoming bonus</p>
                    <p className="text-xxml text-[#FFA98D] blender-medium font-[900] uppercase leading-none">
                      {`${getBonus(userInfo?.referral_level + 1)} bonus`}
                    </p>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
      {/* user referral claim */}
      {referralHistoryList.length !== 0 && (
        <>
          <div className="flex mt-3 relative">
            <div
              className={clsx(
                "absolute flex-shrink-0 w-[140px] md:w-[194px] h-[133px] p-2 pr-4",
                'bg-[url("/assets/dashboard/referral-claim-bg-mb.png")] md:bg-[url("/assets/dashboard/referral-claim-bg-pc.png")] bg-cover bg-center',
                "grid grid-cols-4 gap-2"
              )}
              style={{
                zIndex: 2,
              }}
            >
              <p className="col-span-3 text-[12px] md:text-[14px]">
                Total claimable
              </p>
              <div className="col-span-1">
                <div className="flex justify-center items-center gap-1">
                  <PersonIcon className="size-[14px]" />
                  <p className="text-[12px] md:text-[14px]">
                    {dailyStats?.count}
                  </p>
                </div>
              </div>
              <div className="col-span-4 flex justify-center items-center">
                <GStar className="size-[16px]" />
                <p className="text-xxml text-orange-800 blender-medium font-[900] uppercase leading-none">
                  {dailyStats?.amount || 0}
                </p>
              </div>
              <button
                className={clsx(
                  `${btnStyle} bg-[#FE521933] border-orange-600"`,
                  "col-span-4 w-full p-1 h-[24px] normal-case"
                )}
                onClick={async () => {
                  // TODO: claim all bonus API
                  const result = await postClaimBonus();
                  if (result)
                    openSuccessNotification(
                      "Congratulations! You have claimed all your bonus, keep it up!"
                    );
                }}
              >
                Claim All
              </button>
            </div>
            {/* referral card-list */}

            <div className="flex-1 overflow-hidden">
              <div className={clsx("flex justify-end", "h-[24px]")}>
                {/* change hidden to flex to show button */}
                <button
                  className={clsx(
                    `${btnStyle}`,
                    "p-1 px-2 h-[24px] normal-case",
                    "hidden"
                  )}
                  onClick={() => {
                    setHistoryMode("referral");
                    setDashboardDisplayMode(DISPLAY_MODE_DEFAULT);
                  }}
                >
                  View Transaction History
                </button>
              </div>

              <Marquee
                repeat={2}
                duration={"30s"}
                fromColor="transparent"
                className="mt-2 relative z-0 w-full left-[80px]"
              >
                {referralHistoryList.map((referral, index) => (
                  <div
                    key={referral.user_name}
                    className="p-2 w-[240px] h-[95px] border-[1px] border-[#737373] rounded-xs flex flex-col justify-between"
                  >
                    <div className="flex">
                      <div className="w-[16px] h-[16px] border-[1px] border-[#BF682A] rounded-[50%] overflow-hidden">
                        <img
                          src={
                            referral?.user_avatar ||
                            "/assets/dashboard/user-default.png"
                          }
                          alt="user avatar"
                          className="w-[16px] h-[16px] max-w-[unset]"
                        />
                      </div>
                      <div className="w-full flex justify-between ml-2">
                        <p className="text-[12px]">
                          {referral?.user_name || "user"}
                        </p>
                        <p className="text-[10px]">
                          {referral?.relationship || "direct"}
                        </p>
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="flex justify-between">
                        <p className="text-[10px]">Acquired Date:</p>
                        <p className="text-[10px]">
                          {referral?.created_at || new Date()}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p className="text-[10px]">Generate Bonus:</p>
                        <p className="text-[10px]">{referral?.amount || 0}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
          {/* user total claimed GSTAR */}
          <div className="mt-2 flex items-center">
            <p className="text-grey-800 tracking-[1px] text-[10px]">
              You’ve claimed a total of{" "}
            </p>
            <GStar className="size-[12px] ml-1" />
            <p className="text-grey-800 tracking-[1px] text-[10px]">
              {dailyStats.total_amount}
            </p>
          </div>
        </>
      )}
      {/* referral guide */}
      <p className="mt-2 text-l sm:text-[24px] leading-[19px] sm:leading-[28px] text-grey-100 font-[700] blender-medium">
        Guide
      </p>
      <div className="grid grid-cols-3 gap-3">
        <h1 className="text-[12px] md:text-[16px] text-grey-100 text-center acpex mb-0 col-span-3">
          How to earn your friends bonus rewards?
        </h1>
        <div className="col-span-1 text-center">
          <p className="text-[12px] md:text-[16px] uppercase leading-6">
            Invite friends to get <br /> referral bonus
          </p>
          <p className="text-[8px] md:text-[12px] text-grey-800 mt-6">
            New users can earn 20% special trading <br /> discounts,
          </p>
          <p className="text-[8px] md:text-[12px] text-grey-800 mt-6">
            you can also earn referral rewards from <br /> friends you invite.
          </p>
        </div>
        <div className="col-span-1 text-center">
          <p className="text-[12px] md:text-[16px] uppercase leading-6">
            Subscribe to earn <br /> more bonus
          </p>
          <p className="text-[8px] md:text-[12px] text-grey-800 mt-6">
            Upgrade to Earn 10%
          </p>
          <p className="text-[8px] md:text-[12px] text-grey-800 mt-6">
            (Customized Referral ID)
          </p>
        </div>
        <div className="col-span-1 text-center">
          <p className="text-[12px] md:text-[16px] uppercase leading-6">
            Share to earn <br /> more bonus
          </p>
          <p className="text-[8px] md:text-[12px] text-grey-800 mt-6">
            Trade More to earn more!
          </p>
          <p className="text-[8px] tmd:ext-[12px] text-grey-800 mt-6">
            Receive Daily Rewards to your account
          </p>
        </div>
      </div>
      <h1 className="text-[12px] md:text-[16px] text-grey-100 text-center acpex mt-6 mb-0">
        Ranked up to earn more rewards!
      </h1>
      {/* Rank Level */}
      <div className="flex justify-center items-center mt-5">
        {REFERRAL_LEVELS.map((referralLevel, index) => (
          <>
            <div
              className={clsx(
                "rank-level flex flex-col items-center",
                index + 1 === userInfo?.referral_level ? "" : "opacity-30"
              )}
            >
              <img
                src={`assets/dashboard/referral-${referralLevel.level}.png`}
                alt={`${referralLevel.title}`}
              />
              <p
                className="text-s uppercase"
                style={{ color: referralLevel.mainColor }}
              >
                {referralLevel.title}
              </p>
              <p
                className={clsx(
                  "text-[10px] md:text-s font-bold uppercase mt-2",
                  index + 1 === userInfo?.referral_level
                    ? "text-orange-800"
                    : "text-[#e8e8e8]"
                )}
              >{`${referralLevel.bonus} bonus`}</p>
            </div>
            {index !== REFERRAL_LEVELS.length - 1 ? (
              <div className="text-[#e8e8e8] px-1 md:px-6">{`＞`}</div>
            ) : null}
          </>
        ))}
      </div>
    </div>
  );
};
