import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useMarketplaceStore } from "src/stores";
import { Marquee } from "./marquee";
import { CaseOpeningMysteryBoxItemList } from "src/components/case-opening-mystery-box-item-list";
import { CaseOpeningMysteryBoxItem } from "./case-opening-mystery-box-item";
import { useWebSocket } from "./websocket-context";
import { IMysteryBox } from "src/types/mysteryBox";

export const CaseOpeningRecentWinnings = ({
  boxData,
}: {
  boxData: IMysteryBox | null;
}) => {
  const { id } = useParams<{ id: string }>();
  const { getMysteryBoxRecentWinnings, getMysteryBoxItems } =
    useMarketplaceStore();
  const [recentWinnings, setRecentWinnings] = useState<any[]>([]);
  const { announcedWeapon } = useWebSocket();
  const maxRecentWinnings = 6;

  const fetchRandomMysteryBoxItems = useCallback(
    async (count: number) => {
      const result = await getMysteryBoxItems(id);
      if (!result || typeof result === "string") return;
      if (Array.isArray(result)) {
        const randomItems = [];
        const resultLength = result.length;

        for (let i = 0; i < count; i++) {
          const randomIndex = Math.floor(Math.random() * resultLength);
          const randomItem = {
            ...result[randomIndex],
            weaponPrice: result[randomIndex]["max"],
          };
          randomItems.push(randomItem);
        }

        return randomItems;
      }
    },
    [getMysteryBoxItems, id]
  );

  useEffect(() => {
    if (!id) return;
    const fetchRecentWinnings = async () => {
      const result = await getMysteryBoxRecentWinnings(id);
      if (!result || typeof result === "string") return;
      // result has been sorted by timestamp newest to oldest
      // if result length is less than maxRecentWinnings, fetch more random items
      const recentWinnings =
        result.length >= maxRecentWinnings
          ? result.slice(0, maxRecentWinnings)
          : [
              ...result.slice(0, maxRecentWinnings),
              ...((await fetchRandomMysteryBoxItems(
                maxRecentWinnings - result.length
              )) || []),
            ];
      setRecentWinnings(recentWinnings);
    };

    fetchRecentWinnings();
  }, [id, getMysteryBoxRecentWinnings, fetchRandomMysteryBoxItems]);

  useEffect(() => {
    if (!announcedWeapon) return;
    const {
      userId,
      username,
      itemName,
      itemQuality,
      weaponPrice,
      itemImgUrl,
      boxId,
    } = announcedWeapon;
    if (boxId !== id) return;

    setRecentWinnings((prevWinnings) => {
      const prev = [...prevWinnings];
      return prev.map((item) => ({
        ...item,
        isNewWeapon: false,
      }));
    });

    const newAnnouncement = {
      userId,
      username,
      name: itemName,
      quality: itemQuality,
      weaponPrice,
      imgUrl: itemImgUrl,
      isNewWeapon: true,
    };

    const timeoutId = setTimeout(() => {
      setRecentWinnings((prevWinnings) => {
        const newWinnings = [...prevWinnings];
        if (newWinnings.length >= maxRecentWinnings) {
          newWinnings.pop();
        }
        return [newAnnouncement, ...newWinnings];
      });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [announcedWeapon, id]); // Remove recentWinnings dependency

  // 速度
  const duration = "20s";

  return (
    <CaseOpeningMysteryBoxItemList title="Recent winnings">
      <Marquee repeat={2} duration={duration} className="w-full">
        {recentWinnings?.map((item, i) => {
          return (
            <CaseOpeningMysteryBoxItem
              key={i}
              className={clsx(
                "w-[50vw] sm:w-[15vw] shrink-0 h-[148px] md:h-[192px] aspect-square transition-opacity duration-1000",
                {
                  "animate-fade-down animate-duration-[3000ms] animate-ease-in-out":
                    item.isNewWeapon,
                }
              )}
              type="recent-winnings"
              item={item}
              currency={boxData?.currency}
            />
          );
        })}
      </Marquee>
    </CaseOpeningMysteryBoxItemList>
  );
};
