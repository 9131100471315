import { DashboardTransactionHistoryCaseTab } from "./dashboard-transaction-history-case-tab";
import { DashboardTransactionHistoryCommonTab } from "./dashboard-transaction-history-common-tab";
import { DashboardTransactionHistoryReferralTab } from "./dashboard-transaction-history-referral-tab";
import { IPaginationResponse } from "src/types/dashboard";
import { ReactComponent as GrayHistoryIcon } from "src/svg-icons/gray-list.svg";
import { ReactComponent as OrangeHistoryIcon } from "src/svg-icons/orange-list.svg";
import { ReactComponent as OrangeReferralIcon } from "src/svg-icons/orange-friends.svg";
import { ReactComponent as GrayReferralIcon } from "src/svg-icons/gray-friends.svg";
import { ReactComponent as OrangeInventoryIcon } from "src/svg-icons/orange-inbox.svg";
import { ReactComponent as GrayInventoryIcon } from "src/svg-icons/gray-inbox.svg";

interface iDashboardTransactionHistory {
  dashboardTransactions: any;
  dashboardTransactionsReceivedDate: Date | null;
  getDashboardTransactionsCb: () => void;
  page: number;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  historyMode: string;
  setHistoryMode: React.Dispatch<React.SetStateAction<"case" | "common" | "referral">>;
  paginationResponse: IPaginationResponse;
}

export const DashboardTransactionHistory = (
  props: iDashboardTransactionHistory
) => {
  const {
    dashboardTransactions,
    dashboardTransactionsReceivedDate,
    getDashboardTransactionsCb,
    page,
    pageSize,
    setPage,
    setPageSize,
    historyMode,
    setHistoryMode,
    paginationResponse,
  } = props;

  const tabButtonStyle = `flex flex-row items-center justify-center gap-space-1 text-s sm:text-l leading-[12px] sm:leading-[24px] font-[700] blender-medium normal-case p-space-3 border-0 border-b-[2px] border-solid rounded-[0px] cursor-pointer`;

  return (
    <div
      className="bg-[#00000066] rounded-s min-h-[300px] overflow-hidden backdrop-blur-[8px]"
    >
      <div
        className="grid grid-cols-2 w-full border-0 border-b-[1px] border-solid border-black-200 backdrop-blur-[10px]"
      >
        <div
          className={`${tabButtonStyle} ${
            historyMode === "case"
              ? "text-orange-800 bg-black-600 border-orange-800"
              : "text-[#737373] bg-[#00000066] border-transparent"
          }`}
          onClick={() => setHistoryMode("case")}
        >
          {historyMode === "case" ? <OrangeHistoryIcon className="size-4" /> : <GrayHistoryIcon className="size-4" />}
          <span>History</span>
        </div>
        <div
          className={`${tabButtonStyle} ${
            historyMode === "common"
              ? "text-orange-800 bg-black-600 border-orange-800"
              : "text-[#737373] bg-[#00000066] border-transparent"
          }`}
          onClick={() => setHistoryMode("common")}
        >
          {historyMode === "common" ? <OrangeInventoryIcon className="size-4" /> : <GrayInventoryIcon className="size-4" />}
          <span>Inventory</span>
        </div>
        <div
          className={`${tabButtonStyle} ${
            historyMode === "referral"
              ? "text-orange-800 bg-black-600 border-orange-800"
              : "text-[#737373] bg-[#00000066] border-transparent"
          } hidden`}
          onClick={() => setHistoryMode("referral")}
        >
          {historyMode === "referral" ? <OrangeReferralIcon className="size-4" /> : <GrayReferralIcon className="size-4" />}
          <span>Referral</span>
        </div>
      </div>
      <div className="p-space-2 sm:p-space-4">
        {/* Case history */}
        <div className={`${historyMode === "case" ? "block" : "hidden"}`}>
          <DashboardTransactionHistoryCaseTab
            dashboardTransactions={dashboardTransactions}
            dashboardTransactionsReceivedDate={
              dashboardTransactionsReceivedDate
            }
            getDashboardTransactionsCb={getDashboardTransactionsCb}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            paginationResponse={paginationResponse}
          />
        </div>
        {/* Common history */}
        <div className={`${historyMode === "common" ? "block" : "hidden"}`}>
          <DashboardTransactionHistoryCommonTab
            dashboardTransactions={dashboardTransactions}
            dashboardTransactionsReceivedDate={
              dashboardTransactionsReceivedDate
            }
            getDashboardTransactionsCb={getDashboardTransactionsCb}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            paginationResponse={paginationResponse}
          />
        </div>
        {/* TODO: Referral history */}
        <div className={`${historyMode === "referral" ? "block" : "hidden"}`}>
          <DashboardTransactionHistoryReferralTab
            dashboardTransactions={dashboardTransactions}
            dashboardTransactionsReceivedDate={
              dashboardTransactionsReceivedDate
            }
            getDashboardTransactionsCb={getDashboardTransactionsCb}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            paginationResponse={paginationResponse}
          />
        </div>
      </div>
    </div>
  );
};
